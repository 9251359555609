import React from 'react';

import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const CssLedgerTextField = styled(TextField)({
    textDecoration: "none",
    backgroundColor: 'linear-gradient(0deg, rgba(16, 2, 183, 0.12), rgba(16, 2, 183, 0.12)), #212020',
    '& .MuiInput': {
        color: '#FFFFFF',
    },
    borderColor: "#FFFFFF",
    '& .MuiInput-underline:after': {
        borderBottomColor: '#FFFFFF',
        borderRadius: '0px;',
    },
    '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',
        borderRadius: '0px;',
    },
    '& .MuiInput-root': {
        color: "#FFFFFF",
        '& fieldset': {
            borderColor: '#FFFFFF;',
            borderRadius: '0px;',
            color: '#FFFFFF',
        },
        '&:hover fieldset': {
            borderColor: '#FFFFFF;',
            borderRadius: '0px;',
            color: '#FFFFFF',
        //   backgroundColor: '#F5F7F9',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#FFFFFF',
            color: '#FFFFFF',
        //   backgroundColor: '#F5F7F9',
        },
    },
    '& .MuiInput-input': {
        color: "#FFFFFF",
        fontWeight: "bold",
        '& fieldset': {
            color: '#FFFFFF',
        },
        '&:hover fieldset': {
            color: '#FFFFFF',
        //   backgroundColor: '#F5F7F9',
        },
        '&.Mui-focused fieldset': {
            color: '#FFFFFF',
        //   backgroundColor: '#F5F7F9',
        },
    },
  });

export default CssLedgerTextField;