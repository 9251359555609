import React from 'react';
import { useLocation } from 'react-router-dom';

import { useWindowSize } from '../../libs/responsiveLib';

import VerticalForm from '../../Components/CalculatorTools/RentalIncome/verticalForm';
import VerticalFormMobile from '../../Components/CalculatorTools/RentalIncome/verticalFormMobile';
import CSSLedgerTextField from '../../Components/ComponentCatalog/textFieldLedger';

// Importing calculations
import { propertyTax, rentalIncomeCalculator } from './calculations';

// import CssSelect from '../../Components/ComponentCatalog/select';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/styles';

import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
            onChange({
                target: {
                    name: props.name,
                    value: values.value,
                },
            });
            }}
            thousandSeparator
            isNumericString
        />
    );
}
  
NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const Division = styled(Divider)({
    backgroundColor: "#FFFFFF" 
});

const frequency = [
    {
        value: 'Monthly',
        label: 'Monthly',
    },
    {
        value: 'Semi-monthly',
        label: 'Semi-monthly',
    },
    {
        value: 'Bi-weekly',
        label: 'Bi-weekly',
    },
    {
        value: 'Weekly',
        label: 'Weekly',
    },
];

const propertyType = [
    {
        value: 'House',
        label: 'House',
    },
    {
        value: 'Condo',
        label: 'Condo',
    }
];

export default function RentalIncome() {
    let location = useLocation();
    const size = useWindowSize();

    const [freq, setFreq] = React.useState('Monthly');
    const [propertyPrice, setPropPrice] = React.useState(1000000);
    const [propType, setPropType] = React.useState("House");
    const [propTax, setPropTax] = React.useState('-')
    const [fee, setFee] = React.useState(0);
    const [rent, setRent] = React.useState(1500);
    const [mortgagePayment, setMortgagePayment] = React.useState(0);
    const [total, setTotal] = React.useState(0)

    const handleFreqChange = (event) => {
        setFreq(event.target.value);
    };

    const handlePropChange = (event) => {
        setPropPrice(event.target.value);
    };  

    const handlePropTypeChange = (event) => {
        setPropType(event.target.value);
    };

    const handleFeeChange = (event) => {
        setFee(event.target.value);
    };

    const handleRentChange = (event) => {
        setRent(event.target.value);
    }

    const handleMortgageChange = (event) => {
        setMortgagePayment(event.target.value);
    }

    const handlePropTaxChange = (event) => {
        setPropTax(event.target.value);
    }

    React.useEffect(() => {
        let preTax = rentalIncomeCalculator(Number(propertyPrice), Number(mortgagePayment), Number(freq), Number(rent), Number(fee));

        if (propTax !== "-") {
            var payment = Number(preTax) - Number(propTax);
            setTotal(payment);
        } else {
            setTotal(preTax);
        }
    }, [propertyPrice, freq, mortgagePayment, rent, fee, propTax]);

    React.useEffect(() => {
        var URLproperty = window.location.href.split("/")[4].split("?")[1];
        var propTax

        if (URLproperty) {
            propTax = URLproperty.split("=")[1];
        } else {
            setPropTax("-");
        }

        if (propTax) {
            setPropTax(Number(propTax));
        } else {
            setPropTax("-");
        }
    }, [location]);

    // Determining mobile screen sizing
    const [isMobile, setIsMobile] = React.useState(false);
    React.useEffect(() => {
        setIsMobile(size.width <= 700);
    }, [size]);

    return (
        <div>
            {isMobile ? (
                <div style={{paddingTop: "20px", paddingLeft: "5%", paddingRight: "5%", paddingBottom: "50px"}}>
                    <div>
                        <Typography variant="p" component="div" style={{
                            fontWeight: "bold",
                            color: "#FFFFFF",
                            textAlign: "center"
                        }}>
                            Canadian Rental Income Calculator
                        </Typography>
                    </div>
                    <div style={{paddingTop: "20px"}}>
                        <Typography variant="p" component="div" style={{
                            color: "#FFFFFF",
                            fontSize: "12px"
                        }}>
                            This calculator estimates the net monthly cash flow after setting up 
                            a purchased residence as an income property. This calculation assumes 
                            that the property is rented out immediately after closing transactions, 
                            and that there is no vacancy period. As well, the calculator assumes that 
                            mortgage payments are honored in a timely fashion with no delinquency. 
                            Other factors include property taxes and insurance. Enter your relevant 
                            information to see your net cash flow on a monthly basis.
                        </Typography>
                    </div>
                    <div style={{paddingTop: "40px"}}>
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} style={{
                                    paddingTop: "30px",
                                }}>
                                    <div style={{
                                        paddingBottom: "30px",
                                        border: "1px solid #FFFFFF",
                                        boxShadow: "0px 4px 20px 15px rgba(238, 237, 255, 0.04)",
                                    }}>
                                        <Grid container spacing={2} style={{padding: "20px", paddingTop: "35px"}}>
                                            <Grid item xs={5}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold",
                                                    fontSize: "12px"
                                                }}>
                                                    Property Purchase Price:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={propertyPrice}
                                                    onChange={handlePropChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                $
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold",
                                                    fontSize: "12px"
                                                }}>
                                                    Property Type:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={propType}
                                                    onChange={handlePropTypeChange}
                                                    select
                                                    SelectProps={{
                                                        style: {fontSize: '18px', color: "#FFFFFF"}
                                                    }}
                                                >
                                                    {propertyType.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </CSSLedgerTextField>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold",
                                                    fontSize: "12px"
                                                }}>
                                                    Mortgage Payment:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={mortgagePayment}
                                                    onChange={handleMortgageChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                $
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold",
                                                    fontSize: "12px"
                                                }}>
                                                    Monthly Rent:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={rent}
                                                    onChange={handleRentChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                $
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold",
                                                    fontSize: "12px"
                                                }}>
                                                    Monthly Maintenance Fee:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={fee}
                                                    onChange={handleFeeChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                $
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold",
                                                    fontSize: "12px"
                                                }}>
                                                    Property Tax:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={propTax}
                                                    onChange={handlePropTaxChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                $
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Division style={{marginTop: "5px", marginBottom: "5px"}} />
                                            </Grid>
                                            <Grid item xs={6} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={freq}
                                                    onChange={handleFreqChange}
                                                    select
                                                    SelectProps={{
                                                        style: {fontSize: '22px', color: "#FFFFFF"}
                                                    }}
                                                >
                                                    {frequency.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </CSSLedgerTextField>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold",
                                                    fontSize: "22px"
                                                }}>
                                                    :
                                                </Typography> 
                                            </Grid>
                                            <Grid item xs={5}>
                                                <div style={{textAlign: "right"}}>
                                                    <Typography variant="p" style={{
                                                        color: "#FFFFFF",
                                                        fontWeight: "bold",
                                                        fontSize: "22px",
                                                    }}>
                                                        <NumberFormat
                                                            value={total}
                                                            className="foo"
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            decimalScale={2}
                                                            renderText={(value, props) => <div {...props}>{value}</div>}
                                                        />
                                                    </Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                {/* <Divider classes={{root: classes.divider}} orientation="vertical" flexItem> */}
                                <Grid item xs={12}>
                                    <Typography variant="p" component="div" style={{
                                        paddingTop: "15px",
                                        color: "#FFFFFF",
                                        fontWeight: "bold",
                                        textAlign: "center"
                                    }}>
                                        Calculate Property Tax
                                    </Typography>
                                    <div style={{paddingTop: "20px"}}>
        
                                    </div>
                                    <VerticalFormMobile propertyPrice={propertyPrice} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            ) : (
                <div style={{paddingLeft: "10%", paddingRight: "10%", paddingBottom: "80px"}}>
                    <div style={{
                        paddingTop: "30px"
                    }}>
                        <Typography variant="h5" component="div" style={{
                            fontWeight: "bold",
                            color: "#FFFFFF"
                        }}>
                            Canadian Rental Income Calculator
                        </Typography>
                    </div>
                    <div style={{paddingTop: "20px"}}>
                        <Typography variant="p" component="div" style={{
                            color: "#FFFFFF",
                            lineHeight: "22px"
                        }}>
                            This calculator estimates the net monthly cash flow after setting up 
                            a purchased residence as an income property. This calculation assumes 
                            that the property is rented out immediately after closing transactions, 
                            and that there is no vacancy period. As well, the calculator assumes that 
                            mortgage payments are honored in a timely fashion with no delinquency. 
                            Other factors include property taxes and insurance. Enter your relevant 
                            information to see your net cash flow on a monthly basis.
                        </Typography>
                    </div>
                    <div style={{paddingTop: "40px"}}>
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={7} style={{
                                    paddingTop: "30px",
                                }}>
                                    <div style={{
                                        paddingBottom: "30px",
                                        border: "1px solid #FFFFFF",
                                        boxShadow: "0px 4px 20px 15px rgba(238, 237, 255, 0.04)",
                                    }}>
                                        <Grid container spacing={2} style={{padding: "20px", paddingTop: "35px"}}>
                                            <Grid item xs={6}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold"
                                                }}>
                                                    Property Purchase Price:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={propertyPrice}
                                                    onChange={handlePropChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                $
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold"
                                                }}>
                                                    Property Type:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={propType}
                                                    onChange={handlePropTypeChange}
                                                    select
                                                    SelectProps={{
                                                        style: {fontSize: '18px', color: "#FFFFFF"}
                                                    }}
                                                >
                                                    {propertyType.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </CSSLedgerTextField>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold"
                                                }}>
                                                    Mortgage Payment:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={mortgagePayment}
                                                    onChange={handleMortgageChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                $
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold"
                                                }}>
                                                    Monthly Rent:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={rent}
                                                    onChange={handleRentChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                $
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold"
                                                }}>
                                                    Monthly Maintenance Fee:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={fee}
                                                    onChange={handleFeeChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                $
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold"
                                                }}>
                                                    Property Tax:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={propTax}
                                                    onChange={handlePropTaxChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                $
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Division style={{marginTop: "5px", marginBottom: "5px"}} />
                                            </Grid>
                                            <Grid item xs={6} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={freq}
                                                    onChange={handleFreqChange}
                                                    select
                                                    SelectProps={{
                                                        style: {fontSize: '22px', color: "#FFFFFF"}
                                                    }}
                                                >
                                                    {frequency.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </CSSLedgerTextField>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold",
                                                    fontSize: "22px"
                                                }}>
                                                    :
                                                </Typography> 
                                            </Grid>
                                            <Grid item xs={5}>
                                                <div style={{textAlign: "right"}}>
                                                    <Typography variant="p" style={{
                                                        color: "#FFFFFF",
                                                        fontWeight: "bold",
                                                        fontSize: "22px",
                                                    }}>
                                                        <NumberFormat
                                                            value={total}
                                                            className="foo"
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            decimalScale={2}
                                                            renderText={(value, props) => <div {...props}>{value}</div>}
                                                        />
                                                    </Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                {/* <Divider classes={{root: classes.divider}} orientation="vertical" flexItem> */}
                                <Grid item xs={5}>
                                    <Typography variant="p" style={{
                                        color: "#FFFFFF",
                                        fontWeight: "bold",
                                        fontSize: "18px"
                                    }}>
                                        Calculate Property Tax:
                                    </Typography>
                                    <div style={{paddingTop: "20px"}}>
        
                                    </div>
                                    <VerticalForm propertyPrice={propertyPrice} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>  
            )}
        </div>
    )
}