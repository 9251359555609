import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Importing material-ui packages
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

// Importing icons
import teamIcon from "../../Images/TeamIcon.png";
import analyticsIcon from '../../Images/analyticsIcon.png';
import goalsIcon from '../../Images/goalsIcon.png';
import calendarIcon from '../../Images/calendarIcon.png';
import performanceIcon from '../../Images/PerformanceIcon.png';
import dollarIcon from '../../Images/dollarIcon(Red).png';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
    //   hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
            {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function SuiteSectionMobile(props) {
    const [selected, setSelected] = useState('goalGeneration');

    const handleGoalGeneration = e => {
        setSelected('goalGeneration');
    };

    const handleDaily = e => {
        setSelected('dailyTracking');
    };

    const handlePerformance = e => {
        setSelected('performance');
    };

    const handleAnalytics = e => {
        setSelected('analytics');
    };

    const handleTeam = e => {
        setSelected('teamManagement');
    };

    const handleAdoption = e => {
        setSelected('adoption');
    };

    const ActiveButton = styled(Button)(({ theme }) => ({
        textTransform: 'none',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '26px',
        textAlign: 'center',

        color: '#717595'
    }));
    
    const InActiveButton = styled(Button)(({ theme }) => ({
        textTransform: 'none',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '26px',
        textAlign: 'center',

        color: '#FFFFFF'
    }));

  return (
    <div>
        <Grid container fluid direction="column" spacing={2} justifyContent="center" alignItems="center">
            
            {/* Goal Generation */}
            <div style={{paddingTop: "30px"}}>
                {selected === "goalGeneration" ? (
                    <ActiveButton
                        disableRipple 
                        onClick={handleGoalGeneration}
                    >
                        Goal Generation
                    </ActiveButton>
                ) : (
                    <InActiveButton
                        disableRipple  
                        onClick={handleGoalGeneration}
                    >
                        Goal Generation
                    </InActiveButton>
                )}
            </div>
            {selected === "goalGeneration" && (
                <div style={{textAlign: "center", paddingTop: "30px"}}>
                    <Paper 
                        style={{
                            width: '248px',
                            height: '218px',
                            borderRadius: '0px',
                            boxShadow: '0px 4px 20px 2px rgba(167, 167, 167, 0.15)',
                            padding: "10px",
                            backgroundColor: "#EEEDFF"
                        }}
                        elevation={0}
                    >
                        <div className="icon" style={{paddingTop: '3%'}}>
                            <img src={goalsIcon} alt="Target Icon" width={50} height={50} />
                            <Typography style={{padding: "20px"}}>Tell us your income goal for the year. We will generate a list of what you need to get done to achieve that goal.</Typography>
                        </div>
                    </Paper>
                </div>
            )}

            {/* Daily Tracking */}
            <div style={{paddingTop: "30px"}}>
                {selected === "dailyTracking" ? (
                    <ActiveButton
                        disableRipple 
                        onClick={handleDaily}
                    >
                        Daily Tracking
                    </ActiveButton>
                ) : (
                    <InActiveButton 
                        disableRipple 
                        onClick={handleDaily}
                    >
                        Daily Tracking
                    </InActiveButton>
                )}
            </div>
            {selected === "dailyTracking" && (
                <div style={{textAlign: "center", paddingTop: "30px"}}>
                    <Paper 
                        style={{
                            width: '248px',
                            height: '218px',
                            borderRadius: '0px',
                            boxShadow: '0px 4px 20px 2px rgba(167, 167, 167, 0.15)',
                            padding: "10px",
                            backgroundColor: "#EEEDFF"
                        }}
                        elevation={0}
                    >
                        <div className="icon" style={{paddingTop: '3%'}}>
                            <img src={calendarIcon} alt="Target Icon" width={50} height={50} />
                            <Typography style={{padding: "20px"}}>Track your day-to-day actvities as an agent to stay on top of your game.</Typography>
                        </div>
                    </Paper>
                </div>
            )}

            {/* Performance Review */}
            <div style={{paddingTop: "30px"}}>
                {selected === "performance" ? (
                    <ActiveButton 
                        disableRipple 
                        onClick={handlePerformance}
                    >
                        Performance Review
                    </ActiveButton>
                ) : (
                    <InActiveButton
                        disableRipple  
                        onClick={handlePerformance}
                    >
                        Performance Review
                    </InActiveButton>
                )}
            </div>
            {selected === "performance" && (
                <div style={{textAlign: "center", paddingTop: "30px"}}>
                    <Paper 
                        style={{
                            width: '248px',
                            height: '218px',
                            borderRadius: '0px',
                            boxShadow: '0px 4px 20px 2px rgba(167, 167, 167, 0.15)',
                            padding: "10px",
                            backgroundColor: "#EEEDFF"
                        }}
                        elevation={0}
                    >
                        <div className="icon" style={{paddingTop: '3%'}}>
                            <img src={performanceIcon} alt="Target Icon" width={50} height={50} />
                            <Typography style={{padding: "20px"}}>See how you’re doing compared to your goals. Managers, analyze your team and identify top performers.</Typography>
                        </div>
                    </Paper>
                </div>
            )}

            {/* Analytics and Insights */}
            <div style={{paddingTop: "30px"}}>
                {selected === "analytics" ? (
                    <ActiveButton 
                        disableRipple 
                        onClick={handleAnalytics}
                    >
                        Analytics and Insights
                    </ActiveButton>
                ) : (
                    <InActiveButton 
                        disableRipple
                        onClick={handleAnalytics}
                    >
                        Analytics and Insights
                    </InActiveButton>
                )}
            </div>
            {selected === "analytics" && (
                <div style={{textAlign: "center", paddingTop: "30px"}}>
                    <Paper 
                        style={{
                            width: '248px',
                            height: '218px',
                            borderRadius: '0px',
                            boxShadow: '0px 4px 20px 2px rgba(167, 167, 167, 0.15)',
                            padding: "10px",
                            backgroundColor: "#EEEDFF"
                        }}
                        elevation={0}
                    >
                        <div className="icon" style={{paddingTop: '3%'}}>
                            <img src={analyticsIcon} alt="Target Icon" width={50} height={50} />
                            <Typography style={{padding: "20px"}}>Get relevant insights on performance that tell a story.</Typography>
                        </div>
                    </Paper>
                </div>
            )}

            {/* Team Management */}
            <div style={{paddingTop: "30px"}}>
                {selected === "teamManagement" ? (
                    <ActiveButton 
                        disableRipple 
                        onClick={handleTeam}
                    >
                        Team Management
                    </ActiveButton>
                ) : (
                    <InActiveButton 
                        disableRipple 
                        onClick={handleTeam}
                    >
                        Team Management
                    </InActiveButton>
                )}
            </div>
            {selected === "teamManagement" && (
                <div style={{textAlign: "center", paddingTop: "30px"}}>
                    <Paper 
                        style={{
                            width: '248px',
                            height: '218px',
                            borderRadius: '0px',
                            boxShadow: '0px 4px 20px 2px rgba(167, 167, 167, 0.15)',
                            padding: "10px",
                            backgroundColor: "#EEEDFF"
                        }}
                        elevation={0}
                    >
                        <div className="icon" style={{paddingTop: '3%'}}>
                            <img src={teamIcon} alt="Target Icon" width={50} height={50} />
                            <Typography style={{padding: "20px"}}>Oversee your entire brokerage with one account. Manage and monitor your sales team like never before.</Typography>
                        </div>
                    </Paper>
                </div>
            )}

            {/* Easy Adoption */}
            <div style={{paddingTop: "30px"}}>
                {selected === "adoption" ? (
                    <ActiveButton
                        disableRipple 
                        onClick={handleAdoption}
                    >
                        Easy Adoption
                    </ActiveButton>
                ) : (
                    <InActiveButton 
                        disableRipple
                        onClick={handleAdoption}
                    >
                        Easy Adoption
                    </InActiveButton>
                )}
            </div>
            {selected === "adoption" && (
                <div style={{textAlign: "center", paddingTop: "30px"}}>
                    <Paper 
                        style={{
                            width: '248px',
                            height: '218px',
                            borderRadius: '0px',
                            boxShadow: '0px 4px 20px 2px rgba(167, 167, 167, 0.15)',
                            padding: "10px",
                            backgroundColor: "#EEEDFF"
                        }}
                        elevation={0}
                    >                       
                        <div className="icon" style={{paddingTop: '3%'}}>
                            <img src={dollarIcon} alt="Target Icon" width={50} height={50} />
                            <Typography style={{padding: "20px"}}>Integrate our software with your workflow seamlessly, and get completely set up within the hour.</Typography>
                        </div>
                    </Paper>
                </div>
            )}

        </Grid>
    </div>
  );
}