import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';

import CheckCircle from '../../Images/CheckCircle.png';
import CSSTextField from '../../ComponentCatalog/textField';
  
function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
            onChange({
                target: {
                    name: props.name,
                    value: values.value,
                },
            });
            }}
            thousandSeparator
            isNumericString
        />
    );
}
  
NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#fff',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
      // color: '#D00000',
    },
    circle: {
        width: 64,
        height: 64,
        borderRadius: '50%',
        border: "4px solid #DA3743",
        backgroundColor: 'currentColor',
        zIndex: "100",
    },
    completed: {
        color: '#D00000',
        zIndex: 1,
        fontSize: 18,
    },
});
  
function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
        className={clsx(classes.root, {
            [classes.active]: active,
        })}
        >
        {completed ? <CheckIcon className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}
  
QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
};

  const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#fff',
        zIndex: 100,
        height: 20, 
        width: 20,
        color: '#fff',
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        border: "2px solid #DA3743",
        '& $img': {
            visibility: "hidden"
        },
        '& $active': {
            color: '#FFFFFF'
        },
        '& $disabled': {
            color: "#FFFFFF"
        }
    },
    active: {
        color: '#fff',
        '& $img': {
            visibility: "hidden"
        }
    },
  
    icon: {
        transform: "scale(2.6)",
    },
  
    completed: {
        '& $img': {
            visibility: "visible"
        }
    }
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        // 1: <CheckCircleIcon size="small" className={classes.icon} />,
        1: <img src={CheckCircle} height={20} width={20} alt="check-circle" />,
        2: <img src={CheckCircle} height={20} width={20} alt="check-circle" />,
        3: <img src={CheckCircle} height={20} width={20} alt="check-circle" />,
        4: <img src={CheckCircle} height={20} width={20} alt="check-circle" />
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};
  
function getSteps() {
    return ['Your Brokerage', 'Your Info', 'Confirm Email'];
}

const useStyles = makeStyles({
    divider: {
      backgroundColor: '#FFFFFF',
    //   color: '#FFFFFF'
    },
    stepper: {
        color: "#FFFFFF",
        '&:active': {
            color: "#FFFFFF"
        }
    },
});

const provinces = [
    {
        value: 'ab',
        label: 'Alberta',
    },
    {
        value: 'bc',
        label: 'British Columbia',
    },
    {
        value: 'ma',
        label: 'Manitoba',
    },
    {
        value: 'nb',
        label: 'New Bruinswick',
    },
    {
        value: 'nfl',
        label: 'Newfoundland and Labrador',
    },
    {
        value: 'nwt',
        label: 'Northwest Territories',
    },
    {
        value: 'ns',
        label: 'Nova Scotia',
    },
    {
        value: 'nun',
        label: 'Nunavut',
    },
    {
        value: 'on',
        label: 'Ontario',
    },
    {
        value: 'pei',
        label: 'Price Edward Island',
    },
    {
        value: 'qc',
        label: 'Quebec',
    },
    {
        value: 'sas',
        label: 'Saskatchewan',
    },
    {
        value: 'yu',
        label: 'Yukon',
    },
];

const steps = [
  {
    label: 'Province',
    description: ``,
  },
  {
    label: 'Property Type',
    description: '',
  },
  {
    label: 'Price Details',
    description: ``,
  },
];

const propertyType = [
    {
        value: 'House',
        label: 'House',
    },
    {
        value: 'Condo',
        label: 'Condo',
    }
];

const ColorButton = styled(Button)({
    color: "#FFFFFF",
    backgroundColor: "#000000",
    border: "1px solid transparent",
    '&:hover': {
        border: "1px solid #CC0000",
        backgroundColor: "#000000",
    },
    textTransform:"none",
    borderRadius: '0px',
});

const ColorButtonActive = styled(Button)({
    color: "#FFFFFF",
    backgroundColor: "#000000",
    border: "1px solid #CC0000",
    textTransform:"none",
    borderRadius: '0px',
    '&:hover': {
        backgroundColor: "#000000",
    },
});

export default function InitialInvestmentForm() {

    const classes = useStyles();
    
    const [activeStep, setActiveStep] = React.useState(0);
    const [province, setProvince] = React.useState('on');
    const [propType, setPropType] = React.useState('House');
    const [propertyPrice, setPropPrice] = React.useState(0);
    const [downPay, setDownPay] = React.useState(0);

    const handleProvChange = (event) => {
        setProvince(event.target.value);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    function getStepContent(step) {
        switch (step) {
            case 0:
                return provDetailsForm();
            case 1:
                return propertTypeForm();
            case 2:
                return initialInvestment();
            default:
                return 'Unknown step';
        }
    }

    function provDetailsForm() {
        return (
            <Grid container spacing={2} style={{
                paddingTop: "20px"
            }}>
                <Grid item xs={3} style={{marginTop: "10px"}}>
                    <Typography variant="p" style={{
                        color: "#FFFFFF",
                        fontWeight: "bold"
                    }}>
                        Province:
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <CSSTextField
                        fullWidth 
                        id="custom-css-outlined-input" 
                        value={province}
                        onChange={handleProvChange}
                        select
                    >
                        {provinces.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </CSSTextField>
                </Grid>
            </Grid>
        )
    }

    function propertTypeForm() {
        return (
            <Grid container spacing={2} style={{
                paddingTop: "20px"
            }}>
                <Grid item xs={3} style={{marginTop: "10px"}}>
                    <Typography variant="p" style={{
                        color: "#FFFFFF",
                        fontWeight: "bold"
                    }}>
                        Property Type:
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <CSSTextField
                        fullWidth 
                        id="custom-css-outlined-input" 
                        value={propType}
                        onChange={event => setPropType(event.target.value)}
                        select
                    >
                        {propertyType.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </CSSTextField>
                </Grid>
            </Grid>
        )
    }

    function initialInvestment() {
        return (
            <Grid container spacing={2} style={{
                paddingTop: "20px"
            }}>
                <Grid item xs={4} style={{marginTop: "10px"}}>
                    <Typography variant="p" style={{
                        color: "#FFFFFF",
                        fontWeight: "bold"
                    }}>
                        Property Purchase Price:
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <CSSTextField
                        fullWidth 
                        id="custom-css-outlined-input" 
                        value={propertyPrice}
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                            startAdornment: <InputAdornment position="start">
                                <Typography variant="p" style={{color: "#FFFFFF"}}>
                                    $
                                </Typography>
                            </InputAdornment>,
                        }}
                        inputProps={{
                            style: {fontWeight: "bold", fontSize: "22px", textAlign: "right"}
                        }}
                        onChange={event => setPropPrice(event.target.value)}
                    />
                </Grid>
                <Grid item xs={4} style={{marginTop: "10px"}}>
                    <Typography variant="p" style={{
                        color: "#FFFFFF",
                        fontWeight: "bold"
                    }}>
                        Down Payment:
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <CSSTextField
                        fullWidth 
                        id="custom-css-outlined-input" 
                        value={downPay}
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                            startAdornment: <InputAdornment position="start">
                                <Typography variant="p" style={{color: "#FFFFFF"}}>
                                    $
                                </Typography>
                            </InputAdornment>,
                        }}
                        inputProps={{
                            style: {fontWeight: "bold", fontSize: "22px", textAlign: "right"}
                        }}
                        onChange={event => setDownPay(event.target.value)}
                    />
                </Grid>
            </Grid>
        )
    }

    return (
        <Box sx={{ maxWidth: 700 }}>
            <Stepper 
                activeStep={activeStep} 
                orientation="vertical" 
                classes={{root: classes.stepper, label: classes.stepper}}
            >
                {steps.map((step, index) => (
                    <Step key={step.label}
                        classes={{
                            root: classes.step,
                            completed: classes.completed,
                            active: classes.active
                    }}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                            <div style={{
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                fontSize: "16px"
                            }}>
                                {step.label}
                            </div>
                        </StepLabel>
                        <StepContent>
                            <Typography>{step.description}</Typography>
                            <div>
                                {getStepContent(activeStep)}
                            </div>
                            <Box sx={{ mb: 2 }}>
                                <div style={{paddingTop: "10px"}}>
                                    <ColorButtonActive
                                        disableRipple
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {index === steps.length - 1 ? 'Calculate' : 'Continue'}
                                    </ColorButtonActive>
                                    <ColorButton
                                        disableRipple
                                        variant="contained"
                                        disabled={index === 0}
                                        onClick={handleBack}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Back
                                    </ColorButton>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper
                    square 
                    elevation={0} 
                    sx={{ p: 3 }}
                    style={{
                        backgroundColor: "inherit",
                        border: "1px solid #FFFFFF",
                    }}
                >
                    <Typography
                        style={{
                            color: '#FFFFFF',
                            fontWeight: "bold"
                        }}
                    >
                        Your initial investment would be:
                    </Typography>
                    <Typography
                        style={{
                            color: '#FFFFFF',
                            fontWeight: "bold",
                            paddingTop: "10px",
                            fontSize: "20px",
                            textAlign: "right"
                        }}
                    >
                        $0
                    </Typography>
                    <ColorButton 
                        onClick={handleReset} 
                        sx={{ mt: 1, mr: 1 }}
                    >
                        Reset
                    </ColorButton>
                </Paper>
            )}
        </Box>
    );
}