import React, { Component } from 'react';
import logo from '../Images/Logo(Light).png';
import './logo.css';

class Logo extends Component {
    render() {
        return (
            <div>
                <img src={logo} alt="Real Estate Plus logo" width={202} height={42.2} />
            </div>
        );
    } 
}

export default Logo;