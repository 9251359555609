import * as React from 'react';

// Importing material-ui components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontWeight: "bold",
      fontSize: "15px"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: "#FFFFFF"
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& td, th': {
        border: '0',
    },
    '&:nth-of-type(odd)': {
      backgroundColor: 'linear-gradient(0deg, rgba(16, 2, 183, 0.22), rgba(16, 2, 183, 0.22)), #000000',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                style={{color: "#FFFFFF"}}
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                style={{color: "#FFFFFF"}}
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                style={{color: "#FFFFFF"}}
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                style={{color: "#FFFFFF"}}
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}
  
TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};
  

export default function AmortizationTable(props) {
    
    var rows = props.data
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    // const emptyRows =
    // page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

    return (
        <div>
            {props.isMobile ? (
                <TableContainer style={{border: "1px solid #FFFFFF"}}>
                    <Table sx={{ minWidth: 650 }} size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Period</StyledTableCell>
                                <StyledTableCell align="center">Principal Owed&nbsp;($)</StyledTableCell>
                                <StyledTableCell align="center">Payment&nbsp;($)</StyledTableCell>
                                <StyledTableCell align="center">Interest Paid&nbsp;($)</StyledTableCell>
                                <StyledTableCell align="center">Principal Paid&nbsp;($)</StyledTableCell>
                                <StyledTableCell align="center">Ending Balance&nbsp;($)</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {(rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                        ).map((row) => (
                                <StyledTableRow
                                    key={row['Period']}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell align="center">{row["Period"]}</StyledTableCell>
                                    <StyledTableCell align="center">{row["Principal Owed"]}</StyledTableCell>
                                    <StyledTableCell align="center">{row['Payment']}</StyledTableCell>
                                    <StyledTableCell align="center">{row["Interest Paid"]}</StyledTableCell>
                                    <StyledTableCell align="center">{row["Principal Paid"]}</StyledTableCell>
                                    <StyledTableCell align="center">{row["Ending Balance"]}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <StyledTableRow>
                                <TablePagination
                                    style={{color: "#FFFFFF"}}
                                    rowsPerPageOptions={[10, 25]}
                                    colSpan={3}
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            ) : (
                <div style={{paddingLeft: "50px"}}>
                    <TableContainer style={{border: "1px solid #FFFFFF"}}>
                        <Table sx={{ minWidth: 650 }} size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">Period</StyledTableCell>
                                    <StyledTableCell align="center">Principal Owed&nbsp;($)</StyledTableCell>
                                    <StyledTableCell align="center">Payment&nbsp;($)</StyledTableCell>
                                    <StyledTableCell align="center">Interest Paid&nbsp;($)</StyledTableCell>
                                    <StyledTableCell align="center">Principal Paid&nbsp;($)</StyledTableCell>
                                    <StyledTableCell align="center">Ending Balance&nbsp;($)</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {(rowsPerPage > 0
                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rows
                            ).map((row) => (
                                    <StyledTableRow
                                        key={row['Period']}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <StyledTableCell align="center">{row["Period"]}</StyledTableCell>
                                        <StyledTableCell align="center">{row["Principal Owed"]}</StyledTableCell>
                                        <StyledTableCell align="center">{row['Payment']}</StyledTableCell>
                                        <StyledTableCell align="center">{row["Interest Paid"]}</StyledTableCell>
                                        <StyledTableCell align="center">{row["Principal Paid"]}</StyledTableCell>
                                        <StyledTableCell align="center">{row["Ending Balance"]}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <StyledTableRow>
                                    <TablePagination
                                        style={{color: "#FFFFFF"}}
                                        rowsPerPageOptions={[10, 25]}
                                        colSpan={3}
                                        count={rows.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </StyledTableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            )}
        </div>
    );
}