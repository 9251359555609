
function mortgageCalculator(
        propertyPurchasePrice, 
        downPayment, 
        interestRate, 
        paymentFrequency, 
        amortizationPeriodYears, 
        amortizationPeriodMonths
    )
{
    let p = propertyPurchasePrice - downPayment;
    interestRate = interestRate / 100;
    let paymentsPerYear;
    
    if (paymentFrequency === "Monthly") { paymentsPerYear = 12 };
    if (paymentFrequency === "Semi-monthly") { paymentsPerYear = 24 };
    if (paymentFrequency === "Bi-weekly") { paymentsPerYear = 26 };
    if (paymentFrequency === "Weekly") { paymentsPerYear = 52 };
    
    let r = interestRate / paymentsPerYear
    let amortizationPeriod = amortizationPeriodYears + (amortizationPeriodMonths / 12)
    let n = amortizationPeriod * paymentsPerYear
    let mortgagePayment = p * ((r * ((1 + r) ** n)) / (((1 + r) ** n) - 1))
    
    return mortgagePayment
}

function rentalIncomeCalculator(propertyPurchasePrice, mortgagePayment, paymentFrequency, rent, maintenanceFee) {
    
    let propertyInsurance = propertyPurchasePrice * (0.0031 / 12)
    let paymentsPerYear;

    if (paymentFrequency === "Monthly") {
        paymentsPerYear = 12
    }
    else if (paymentFrequency === "Semi-monthly") {
        paymentsPerYear = 24
    }
    else if (paymentFrequency === "Bi-weekly") {
        paymentsPerYear = 26
    }
    else if (paymentFrequency === "Weekly") {
        paymentsPerYear = 52
    }    
    
    mortgagePayment = mortgagePayment * (paymentsPerYear / 12)

    let rentalIncome = rent

    rentalIncome = rentalIncome - (propertyInsurance + maintenanceFee)
    
    return rentalIncome
}

function propertyTax(optionList, city, propertyPrice) {
    
    var rate;

    for (var i = 0; i < optionList.length; i++) {
        if (optionList[i]["City"] === city) {
            rate = optionList[i]["Rate"]
        }
    };

    var totalTaxPayment = Number(propertyPrice) * Number(rate);

    return totalTaxPayment
}

export { rentalIncomeCalculator, mortgageCalculator, propertyTax };