import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';
import config from './aws-exports';

// Importing Google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('G-Z4WY4WPVQE');
ReactGA.pageview(window.location.pathname + window.location.search);

Amplify.configure(config);
// document.body.style = 'background-image: linear-gradient(161.21deg, #494747 -2.27%, rgba(73, 71, 71, 0.67) 105.94%);'
document.body.style = 'background-color: #FFFFFF;'

ReactDOM.render(
  <React.StrictMode>
    <div className="body">
      <App />
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
