import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useWindowSize } from '../../libs/responsiveLib';

import VerticalForm from '../../Components/CalculatorTools/Mortgage/verticalForm';
import VerticalFormMobile from '../../Components/CalculatorTools/Mortgage/verticalFormMobile';
import CSSLedgerTextField from '../../Components/ComponentCatalog/textFieldLedger';
import AmortizationGraph from '../../Components/Graphing/amortizationGraph';
import PrincipalGraph from '../../Components/Graphing/principalGraph';
import AmortizationTable from '../../Components/Graphing/amortizationTable';

// Importing calculations
import { mortgageCalculator } from './calculations';

// import CssSelect from '../../Components/ComponentCatalog/select';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import RefreshIcon from '@mui/icons-material/Refresh';

// Importing axios for rest api calls
var axios = require('axios');

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
            onChange({
                target: {
                    name: props.name,
                    value: values.value,
                },
            });
            }}
            thousandSeparator
            isNumericString
        />
    );
}
  
NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const Division = styled(Divider)({
    backgroundColor: "#FFFFFF" 
});

const frequency = [
    {
        value: 'Monthly',
        label: 'Monthly',
    },
    {
        value: 'Semi-monthly',
        label: 'Semi-monthly',
    },
    {
        value: 'Bi-weekly',
        label: 'Bi-weekly',
    },
    {
        value: 'Weekly',
        label: 'Weekly',
    },
];

export default function MortgageCalculator() {
    let location = useLocation();
    const size = useWindowSize();

    const [freq, setFreq] = React.useState('Monthly');
    const [tempPropertyPrice, setTempPropPrice] = React.useState(1000000);
    const [propertyPrice, setPropPrice] = React.useState(1000000);
    const [tempDPP, setDPP] = React.useState(20);
    const [downPayPer, setDPper] = React.useState(20);
    const [tempDPA, setDPA] = React.useState(200000);
    const [downPayAmt, setDPamt] = React.useState(200000);
    const [intRate, setIntRate] = React.useState(2);
    const [propertyTax, setPropTax] = React.useState('-');
    const [years, setYears] = React.useState(20);
    const [months, setMonths] = React.useState(0);
    const [mortgagePayment, setMortgagePayment] = React.useState(0);
    const [data, setData] = React.useState();
    const [lastPayment, setLP] = React.useState();
    const [preTax, setPreTax] = React.useState();

    const handleFreqChange = (event) => {
        setFreq(event.target.value);
    };

    React.useEffect(() => {
        const timer = setTimeout(() => setPropPrice(tempPropertyPrice), 1000);
        return () => clearTimeout(timer);
    }, [tempPropertyPrice]);  

    React.useEffect(() => {
        const timer = setTimeout(() => setDPper(tempDPP), 1000);
        return () => clearTimeout(timer);   
    });

    React.useEffect(() => {
        const timer = setTimeout(() => setDPamt(tempDPA), 1000);
        return () => clearTimeout(timer);   
    });

    const handleIntChange = (event) => {
        setIntRate(event.target.value);
    };    

    const handlePropTaxChange = (event) => {
        setPropTax(event.target.value);
    };

    const handleYearsChange = (event) => {
        setYears(event.target.value);
    };

    const handleMonthsChange = (event) => {
        setMonths(event.target.value);
    };

    React.useEffect(() => {
        var preTaxMort = mortgageCalculator(Number(propertyPrice), Number(downPayAmt), Number(intRate), freq, Number(years), Number(months));
        setPreTax(preTaxMort);
        setMortgagePayment(preTaxMort);

        if (propertyTax !== "-") {
            var payment = preTax + Number(propertyTax);
            setMortgagePayment(payment);
        }
    }, [propertyPrice, downPayAmt, intRate, freq, years, months, propertyTax]);

    React.useEffect(() => {
        console.log(preTax);
        if (!data) {
            refreshAmortizationData();
            console.log(data);
        }
    }, [preTax, data]);

    React.useEffect(() => {
        if (Number(propertyPrice) > 0) {
            let amt = (downPayAmt / propertyPrice) * 100;
            setDPper(amt.toFixed(2));
            setDPP(amt.toFixed(2));
        }
    }, [downPayAmt, propertyPrice]);

    React.useEffect(() => {
        if (propertyPrice) {
            let amt = propertyPrice * (downPayPer/100)
            setDPamt(amt.toFixed(0));
            setDPA(amt.toFixed(0));
        }
    }, [downPayPer]);

    React.useEffect(() => {
        var URLproperty = window.location.href.split("/")[4].split("?")[1];
        var propTax;

        if (URLproperty) {
            propTax = URLproperty.split("=")[1];
        } else {
            setPropTax("-");
        }

        if (propTax) {
            setPropTax(Number(propTax));
        } else {
            setPropTax("-");
        }
    }, [location]);

    // Determining mobile screen sizing
    const [isMobile, setIsMobile] = React.useState(false);
    React.useEffect(() => {
        setIsMobile(size.width <= 700);
    }, [size]);

    // ******* Code for axios amortization API call *******

    function refreshAmortizationData() {
        // if (preTax > 0) {
            // Placeholder data for amortization API call
            
            let principalAmt = Number(propertyPrice) - Number(downPayAmt);
            
            var data = JSON.stringify({
                "mortgagePayment": Number(preTax),
                "principalAmount": Number(principalAmt),
                "amortizationPeriodYears": Number(years),
                "amortizationPeriodMonths": Number(months),
                "mortgageRate": Number(intRate),
                "paymentFrequency": freq
            });

            var config = {
                method: 'post',
                url: 'https://g90bq8eoxl.execute-api.us-east-2.amazonaws.com/development/amortizationschedule',
                headers: {},
                data : data
            };

            axios(config)
            .then(function (response) {
                console.log("FUNCTION CALL");
                setData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

            setLP(preTax);
        // }
    };

    const ColorButton = styled(Button)(({ theme }) => ({
        textTransform: "none",
        fontWeight: "bold",
        color: "#FFFFFF",
        backgroundColor: "#000000",
        border: "1px solid transparent",
        '&:hover': {
            border: "1px solid #CC0000",
            backgroundColor: "#000000",
        },
        borderRadius: '0px',
    }));

    return (
        <div>
            {/* Mobile responsiveness */}
            {isMobile ? (
                <div style={{paddingTop: "20px", paddingLeft: "5%", paddingRight: "5%", paddingBottom: "50px"}}>
                    <div>
                        <Typography variant="p" component="div" style={{
                            fontWeight: "bold",
                            color: "#FFFFFF",
                            textAlign: "center"
                        }}>
                            Canadian Mortgage Calculator
                        </Typography>
                    </div>
                    <div style={{paddingTop: "20px"}}>
                        <Typography variant="p" component="div" style={{
                            color: "#FFFFFF",
                            fontSize: "12px"
                        }}>
                            Mortgage payments for Canadians stem beyond just a simple 
                            monthly payment. Property taxes, interest rates, and amortization periods are 
                            all items to critically consider when purchasing a residence. Traditionally, 
                            Canadians tended to obligate themselves to monthly payments. Today, lenders and 
                            mortgage brokers allow homeowners to pay off their mortgages using monthly, 
                            semi-monthly, bi-weekly, and weekly payments.
                        </Typography>
                    </div>
                    <div style={{paddingTop: "40px"}}>
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} style={{
                                    paddingTop: "30px",
                                }}>
                                    <div style={{
                                        paddingBottom: "30px",
                                        border: "1px solid #FFFFFF",
                                        boxShadow: "0px 4px 20px 15px rgba(238, 237, 255, 0.04)",
                                    }}>
                                        <Grid container spacing={1} style={{padding: "20px", paddingTop: "35px"}}>
                                            <Grid item xs={5}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold",
                                                    fontSize: "12px"
                                                }}>
                                                    Property Purchase Price:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={tempPropertyPrice}
                                                    onChange={event => setTempPropPrice(event.target.value)}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                $
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold",
                                                    fontSize: "12px"
                                                }}>
                                                    Down Payment:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={tempDPP}
                                                    onChange={event => setDPP(event.target.value)}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                %
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard"
                                                    value={tempDPA}
                                                    onChange={event => setDPA(event.target.value)} 
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                $
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold",
                                                    fontSize: "12px"
                                                }}>
                                                    Interest Rate:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={intRate}
                                                    onChange={handleIntChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                %
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold",
                                                    fontSize: "12px"
                                                }}>
                                                    Property Tax:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={propertyTax}
                                                    onChange={handlePropTaxChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                $
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold",
                                                    fontSize: "12px"
                                                }}>
                                                    Loan Term (YY/MM):
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={years}
                                                    onChange={handleYearsChange}
                                                    InputProps={{
                                                        endAdornment: 
                                                            <InputAdornment position="start">
                                                                <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                    Years
                                                                </Typography>
                                                            </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={months}
                                                    onChange={handleMonthsChange}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                Months
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Division style={{marginTop: "5px", marginBottom: "5px"}} />
                                            </Grid>
                                            <Grid item xs={6} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={freq}
                                                    onChange={handleFreqChange}
                                                    select
                                                    SelectProps={{
                                                        style: {fontSize: '22px', color: "#FFFFFF"}
                                                    }}
                                                >
                                                    {frequency.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </CSSLedgerTextField>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold",
                                                    fontSize: "22px"
                                                }}>
                                                    :
                                                </Typography> 
                                            </Grid>
                                            <Grid item xs={5}>
                                                <div style={{textAlign: "right"}}>
                                                    <Typography variant="p" style={{
                                                        color: "#FFFFFF",
                                                        fontWeight: "bold",
                                                        fontSize: "22px",
                                                    }}>
                                                        <NumberFormat
                                                            value={mortgagePayment}
                                                            className="foo"
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            decimalScale={2}
                                                            renderText={(value, props) => <div {...props}>{value}</div>}
                                                        />
                                                    </Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                {/* <Divider classes={{root: classes.divider}} orientation="vertical" flexItem> */}
                                <Grid item xs={12}>
                                    <Typography variant="p" component="div" style={{
                                        paddingTop: "15px",
                                        color: "#FFFFFF",
                                        fontWeight: "bold",
                                        textAlign: "center"
                                    }}>
                                        Calculate Property Tax
                                    </Typography>
                                    <div style={{paddingTop: "20px"}}>
                                    </div>
                                    <VerticalFormMobile propertyPrice={propertyPrice} />
                                </Grid>
                            </Grid>
                            <div style={{
                                paddingTop: "60px"
                            }}>
                                {data && (
                                    <div>
                                        <Stack
                                            direction={{ xs: 'column', sm: 'row' }}
                                            spacing={{ xs: 1, sm: 2, md: 4 }}
                                            alignItems="center"
                                        >
                                            <Typography variant="p" style={{
                                                color: "#FFFFFF",
                                                fontWeight: "bold",
                                                fontSize: "18px"
                                            }}>
                                                Amortization Schedule
                                            </Typography>
                                            <Typography variant="p" style={{
                                                color: "#FFFFFF",
                                                fontWeight: "bold",
                                                fontSize: "14px"
                                            }}>
                                                {lastPayment && (
                                                    <div>
                                                        graphing <NumberFormat value={lastPayment} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2}/> payments over {data.length} periods
                                                    </div>
                                                )}
                                            </Typography>
                                            <div>
                                                <ColorButton 
                                                    startIcon={<RefreshIcon />} 
                                                    variant="contained"
                                                    onClick={refreshAmortizationData}
                                                >
                                                    Refresh
                                                </ColorButton>
                                            </div>
                                        </Stack>
                                        <div style={{paddingTop: "40px"}}>
                                            <AmortizationGraph data={data} isMobile={isMobile} />
                                        </div>
                                        <div style={{paddingTop: "60px"}}>
                                            <PrincipalGraph data={data} isMobile={isMobile}/>
                                        </div>
                                        <div style={{paddingTop: "50px"}}>
                                            <AmortizationTable data={data} isMobile={isMobile} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div style={{paddingLeft: "10%", paddingRight: "10%", paddingBottom: "80px"}}>
                    <div style={{
                        paddingTop: "30px"
                    }}>
                        <Typography variant="h5" component="div" style={{
                            fontWeight: "bold",
                            color: "#FFFFFF"
                        }}>
                            Canadian Mortgage Calculator
                        </Typography>
                    </div>
                    <div style={{paddingTop: "20px"}}>
                        <Typography variant="p" component="div" style={{
                            color: "#FFFFFF",
                            lineHeight: "22px"
                        }}>
                            Mortgage payments for Canadians stem beyond just a simple 
                            monthly payment. Property taxes, interest rates, and amortization periods are 
                            all items to critically consider when purchasing a residence. Traditionally, 
                            Canadians tended to obligate themselves to monthly payments. Today, lenders and 
                            mortgage brokers allow homeowners to pay off their mortgages using monthly, 
                            semi-monthly, bi-weekly, and weekly payments.
                        </Typography>
                    </div>
                    <div style={{paddingTop: "40px"}}>
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={7} style={{
                                    paddingTop: "30px",
                                }}>
                                    <div style={{
                                        paddingBottom: "30px",
                                        border: "1px solid #FFFFFF",
                                        boxShadow: "0px 4px 20px 15px rgba(238, 237, 255, 0.04)",
                                    }}>
                                        <Grid container spacing={2} style={{padding: "20px", paddingTop: "35px"}}>
                                            <Grid item xs={6}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold"
                                                }}>
                                                    Property Purchase Price:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={tempPropertyPrice}
                                                    onChange={event => setTempPropPrice(event.target.value)}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                $
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold"
                                                }}>
                                                    Down Payment:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={tempDPP}
                                                    onChange={event => setDPP(event.target.value)}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                %
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard"
                                                    value={tempDPA}
                                                    onChange={event => setDPA(event.target.value)} 
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                $
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold"
                                                }}>
                                                    Interest Rate (Fixed):
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={intRate}
                                                    onChange={handleIntChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                %
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold"
                                                }}>
                                                    Property Tax:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={propertyTax}
                                                    onChange={handlePropTaxChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                $
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        style: {textAlign: "right", fontSize: '18px'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold"
                                                }}>
                                                    Loan Term (YY/MM):
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={years}
                                                    onChange={handleYearsChange}
                                                    InputProps={{
                                                        endAdornment: 
                                                            <InputAdornment position="start">
                                                                <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                    Years
                                                                </Typography>
                                                            </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField 
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={months}
                                                    onChange={handleMonthsChange}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="start">
                                                            <Typography variant="p" style={{color: "#FFFFFF"}}>
                                                                Months
                                                            </Typography>
                                                        </InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Division style={{marginTop: "5px", marginBottom: "5px"}} />
                                            </Grid>
                                            <Grid item xs={6} style={{marginTop: "-5px"}}>
                                                <CSSLedgerTextField
                                                    fullWidth 
                                                    variant="standard" 
                                                    value={freq}
                                                    onChange={handleFreqChange}
                                                    select
                                                    SelectProps={{
                                                        style: {fontSize: '22px', color: "#FFFFFF"}
                                                    }}
                                                >
                                                    {frequency.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </CSSLedgerTextField>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="p" style={{
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold",
                                                    fontSize: "22px"
                                                }}>
                                                    :
                                                </Typography> 
                                            </Grid>
                                            <Grid item xs={5}>
                                                <div style={{textAlign: "right"}}>
                                                    <Typography variant="p" style={{
                                                        color: "#FFFFFF",
                                                        fontWeight: "bold",
                                                        fontSize: "22px",
                                                    }}>
                                                        <NumberFormat
                                                            value={mortgagePayment}
                                                            className="foo"
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            decimalScale={2}
                                                            renderText={(value, props) => <div {...props}>{value}</div>}
                                                        />
                                                    </Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                {/* <Divider classes={{root: classes.divider}} orientation="vertical" flexItem> */}
                                <Grid item xs={5}>
                                    <Typography variant="p" style={{
                                        color: "#FFFFFF",
                                        fontWeight: "bold",
                                        fontSize: "18px"
                                    }}>
                                        Calculate Property Tax:
                                    </Typography>
                                    <div style={{paddingTop: "20px"}}>
        
                                    </div>
                                    <VerticalForm propertyPrice={propertyPrice} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div style={{
                        paddingTop: "60px"
                    }}>
                        {data && (
                            <div>
                                <Stack
                                    direction={{ xs: 'column', sm: 'row' }}
                                    spacing={{ xs: 1, sm: 2, md: 4 }}
                                    alignItems="center"
                                >
                                    <Typography variant="p" style={{
                                        color: "#FFFFFF",
                                        fontWeight: "bold",
                                        fontSize: "18px"
                                    }}>
                                        Amortization Schedule
                                    </Typography>
                                    <Typography variant="p" style={{
                                        color: "#FFFFFF",
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}>
                                        {lastPayment && (
                                            <div>
                                                graphing <NumberFormat value={lastPayment} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2}/> payments over {data.length} periods
                                            </div>
                                        )}
                                    </Typography>
                                    <div>
                                        <ColorButton 
                                            startIcon={<RefreshIcon />} 
                                            variant="contained"
                                            onClick={refreshAmortizationData}
                                        >
                                            Refresh
                                        </ColorButton>
                                    </div>
                                </Stack>
                                <div style={{paddingTop: "60px"}}>
                                    <AmortizationGraph data={data} isMobile={isMobile} />
                                </div>
                                <div style={{paddingTop: "60px"}}>
                                    <PrincipalGraph data={data} isMobile={isMobile} />
                                </div>
                                <div style={{paddingTop: "50px"}}>
                                    <AmortizationTable data={data} isMobile={isMobile} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}