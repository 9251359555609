/*
    Author: Ibai Seco
    Description: Provides application routing / For usage see src/App.js
*/

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import NotFound from "./Pages/notFound";
import Home from './Pages/promoLanding';
import MortgageCalculator from "./Pages/Calculators/mortgageCalculator";
import CalculatorHome from './Pages/Calculators/home';
import Header from './Components/Headers/header';
import RentalIncome from "./Pages/Calculators/rentalIncome";
import InitialInvestment from './Pages/Calculators/initialInvestment';
import Developers from './Pages/developers';

// Since routes are regular React components, they
// may be rendered anywhere in the app, including in
// child elements.
//
// This helps when it's time to code-split your app
// into multiple bundles because code-splitting a
// React Router app is the same as code-splitting
// any other React app.

export default function NestingExample() {
  return (
      <div>
        <Router>
            <Switch>
              <Route exact path="/">
                <Redirect to="/business-tools" />
              </Route>
              <Route path="/calculators">
                <div className="Header">
                  <Header />
                </div>
                <Switch>
                  <Route exact path="/calculators">
                    <Redirect to="/calculators/mortgage-calculator" />
                  </Route>
                  <Route exact path="/calculators/mortgage-calculator">
                    <MortgageCalculator />
                  </Route>
                  <Route exact path="/calculators/rental-income">
                    <RentalIncome />
                  </Route>
                  <Route exact path="/calculators/initial-investment">
                    <InitialInvestment />
                  </Route>
                </Switch>
              </Route>
              <Route exact path="/business-tools">
                <div className="Header">
                  <Header />
                </div>
                <Home />
              </Route>
              <Route exact path="/developers">
                <div className="Header">
                  <div className="Header">
                    <Header />
                  </div>
                  <Developers />
                </div>
                <CalculatorHome />
              </Route>
              <Route>
                <div className="Header">
                  <Header />
                </div>
                <NotFound />
              </Route>
            </Switch>
        </Router>
      </div>
  );
}
