
import { 
    ComposedChart, 
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip, 
    ReferenceLine, 
    ResponsiveContainer,
    Area 
} from 'recharts';

import Typography from '@mui/material/Typography';

export default function PrincipalGraph(props) {
    
    var amortData = props.data;
    
    return (
        <div>
            <ResponsiveContainer width="99%" height={260}>
                <ComposedChart data={amortData}>
                    <CartesianGrid horizontal={false} vertical={false} />
                    <ReferenceLine y={0} stroke="#000000" />
                    <defs>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#000000" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#000000" stopOpacity={0}/>
                        </linearGradient>
                    </defs>

                    <Area 
                        type="monotone" 
                        dataKey="Principal Owed" 
                        stroke="#FFFFFF" 
                        label="Principal Paid"
                        strokeWidth={2}
                        fillOpacity={1}
                        fill="url(#colorPv)"
                    />

                    <Tooltip
                        formatter={(value) => new Intl.NumberFormat('en').format(value)}
                        contentStyle={{
                            color: "#FFFFFF",
                            borderRadius:"10px",
                            backgroundColor: "#000000",
                            boxShadow:"0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06)"
                        }}
                        cursor={false}
                    />

                    <XAxis 
                        dataKey="Period" 
                        tickLine={false}
                        stroke="#FFFFFF"
                        axisLine={{stroke: "#FFFFFF"}}
                        dy={12} 
                        scale="band"
                    />

                    {/* <YAxis scale="log" domain={['auto', 'auto']} /> */}
                    {!props.isMobile && (
                        <YAxis  
                            stroke="#FFFFFF"
                            axisLine={{stroke: "#FFFFFF"}}  
                            tickLine={false}
                            tickFormatter={tick => {
                                return tick.toLocaleString();
                            }}
                            tick={{fontSize: 13}}
                            dy={-2}
                        />
                    )}
                </ComposedChart>
            </ResponsiveContainer>
            <div style={{paddingTop: "20px"}}>
                <Typography style={{
                    color: "#FFFFFF",
                    fontWeight: "bold",
                    textAlign: "center"
                }}>
                    Period
                </Typography>
            </div>
        </div>
    )
}