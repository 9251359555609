import { motion } from "framer-motion";
import dollarIcon from "../Images/dollarIcon.png";

export default function FloatingDollarIcons() {
    return (
        <div>
            <motion.div
                initial={{
                    x: '-80%',
                    y: '20%'
                }}
                animate={{
                    x: '120%',
                    y: '50%'
                }}
                transition={{
                    type: 'tween',
                    ease: 'easeInOut',
                    repeat: Infinity,
                    // repeatType: 'reverse',
                    repeatDelay: 0,
                    duration: 4,
                    delay: 1
                }}
            >
                <img src={dollarIcon} alt="Floating Team Icon" width={81} height={81} />
            </motion.div>
            <motion.div
                initial={{
                    x: '-80%',
                }}
                animate={{
                    x: '120%',
                    y: '-20%'
                }}
                transition={{
                    type: 'tween',
                    ease: 'easeInOut',
                    repeat: Infinity,
                    // repeatType: 'cycle',
                    repeatDelay: 0,
                    duration: 4,
                    delay: 2
                }}
            >
                <img src={dollarIcon} alt="Floating Team Icon" width={81} height={81} />
            </motion.div>
            <motion.div
                initial={{
                    x: '-80%',
                }}
                animate={{
                    x: '120%',
                    y: '-45%'
                }}
                transition={{
                    type: 'tween',
                    ease: 'easeInOut',
                    repeat: Infinity,
                    // repeatType: 'r',
                    repeatDelay: 0,
                    duration: 4,
                    delay: 3
                }}
            >
                <img src={dollarIcon} alt="Floating Team Icon" width={81} height={81} />
            </motion.div>
        </div>
    )
}