import React from 'react';

import InitialInvestmentForm from '../../Components/CalculatorTools/InitialInvestment/form';
import CSSLedgerTextField from '../../Components/ComponentCatalog/textFieldLedger';

// Importing calculations
import { rentalIncomeCalculator } from './calculations';

// import CssSelect from '../../Components/ComponentCatalog/select';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/styles';

import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
            onChange({
                target: {
                    name: props.name,
                    value: values.value,
                },
            });
            }}
            thousandSeparator
            isNumericString
        />
    );
}
  
NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const Division = styled(Divider)({
    backgroundColor: "#FFFFFF" 
});

const frequency = [
    {
        value: 'Monthly',
        label: 'Monthly',
    },
    {
        value: 'Semi-monthly',
        label: 'Semi-monthly',
    },
    {
        value: 'Bi-weekly',
        label: 'Bi-weekly',
    },
    {
        value: 'Weekly',
        label: 'Weekly',
    },
];

const propertyType = [
    {
        value: 'House',
        label: 'House',
    },
    {
        value: 'Condo',
        label: 'Condo',
    }
];

export default function InitialInvestment() {

    const [freq, setFreq] = React.useState('Monthly');
    const [propertyPrice, setPropPrice] = React.useState(1000000);
    const [propType, setPropType] = React.useState("House");
    const [propTax, setPropTax] = React.useState('-')
    const [fee, setFee] = React.useState(0);
    const [rent, setRent] = React.useState(1500);
    const [mortgagePayment, setMortgagePayment] = React.useState(0);
    const [total, setTotal] = React.useState(0)

    const handleFreqChange = (event) => {
        setFreq(event.target.value);
    };

    const handlePropChange = (event) => {
        setPropPrice(event.target.value);
    };  

    const handlePropTypeChange = (event) => {
        setPropType(event.target.value);
    };

    const handleFeeChange = (event) => {
        setFee(event.target.value);
    };

    const handleRentChange = (event) => {
        setRent(event.target.value);
    }

    const handleMortgageChange = (event) => {
        setMortgagePayment(event.target.value);
    }

    const handlePropTaxChange = (event) => {
        setPropTax(event.target.value);
    }

    React.useEffect(() => {
        setTotal(rentalIncomeCalculator(Number(propertyPrice), Number(mortgagePayment), Number(freq), Number(rent), Number(fee)));
    }, [propertyPrice, freq, mortgagePayment, rent, fee]);

    return (
        <div style={{paddingLeft: "10%", paddingRight: "10%", paddingBottom: "80px"}}>
            <div style={{
                paddingTop: "30px"
            }}>
                <Typography variant="h5" component="div" style={{
                    fontWeight: "bold",
                    color: "#FFFFFF",
                }}>
                    Canadian Initial Investment Calculator
                </Typography>
            </div>
            <div style={{paddingTop: "20px"}}>
                <Typography variant="p" component="div" style={{
                    color: "#FFFFFF",
                    lineHeight: "22px"
                }}>
                    Down payments are often considered in absolutes. 
                    It is crucial to note that there are many hidden 
                    costs and expenses that are added onto the total 
                    amount of cash needed for the initial purchase of 
                    a property. This calculator takes into account those 
                    “fine print” costs such as land transfer tax, mortgage
                    insurance premiums (if needed), lawyer fees, title insurance, 
                    realtor commission, and estoppel certificates (for condos).
                </Typography>
            </div>
            <div style={{paddingTop: "40px"}}>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{
                            paddingTop: "30px",
                            paddingLeft: "10%",
                            paddingRight: "10%"
                        }}>
                            <InitialInvestmentForm />
                        </Grid>
                        {/* <Divider classes={{root: classes.divider}} orientation="vertical" flexItem> */}
                    </Grid>
                </div>
            </div>
        </div>
    )
}