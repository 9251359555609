import React from 'react';

import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from '../../libs/responsiveLib';

// importing material-ui components
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';


// importing logo
import Logo from '../LandingPage/Logo';
import SvgButton from './headerButton';
import SvgButtonActive from './headerButtonActive';
import CalculatorHeader from './calculatorHeader';
import CalculatorHeaderMobile from './calculatorHeaderMobile';

console.log(process.env.REACT_APP_MARKETSTACK_KEY)
console.log(process.env.REACT_APP_MARKETSTACK_KEY)

// function fetchStock() {
//     let API_KEY = process.env.REACT_APP_MARKETSTACK_KEY
//     let stockTicker = 'MSFT'
//     let URL = `http://api.marketstack.com/v1/eod access_key=${API_KEY}&symbols=${stockTicker}`

//     fetch(URL).then(res => res.json())
//     .then(data => console.log(data))
// }

export default function Header() {

    const location = useLocation();
    const history = useHistory();
    const size = useWindowSize();

    const isCalculators = (location.pathname === "/calculators/mortgage-calculator" ||
    location.pathname === "/calculators/rental-income" ||
    location.pathname === "/calculators/initial-investment" ||
    location.pathname === "/calculators/amortization-schedule");
    const isMarketTools = location.pathname === "/business-tools"
    const isDevelopers = location.pathname === "/developers"

    // Determining mobile screen sizing
    const [isMobile, setIsMobile] = React.useState(false);
    React.useEffect(() => {
        setIsMobile(size.width <= 600);
    }, [size]);

    return (
    
        <div>
            {isMobile ? (
                <div style={{paddingTop: "30px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{textAlign:"center"}}>
                            <Logo />
                        </Grid>
                        <Grid item xs={12} style={{textAlign:"center"}}>
                            <Stack spacing={2} direction="row" justifyContent="center">
                                {isCalculators ? (
                                    <SvgButtonActive onClick={() => {history.push('/calculators/mortgage-calculator')}}>
                                        Calculators
                                    </SvgButtonActive>
                                ) : (
                                    <SvgButton onClick={() => {history.push('/calculators/mortgage-calculator')}}>
                                        Calculators
                                    </SvgButton>
                                )}
                                {isMarketTools ? (
                                    <SvgButtonActive onClick={() => {history.push('/business-tools')}}>
                                        business tools
                                    </SvgButtonActive>
                                ) : (
                                    <SvgButton onClick={() => {history.push('/business-tools')}}>
                                        business tools
                                    </SvgButton>
                                )}
                                {/* {isDevelopers ? (
                                    <SvgButtonActive onClick={() => {history.push('/developers')}}>
                                        developers
                                    </SvgButtonActive>
                                ) : (
                                    <SvgButton onClick={() => {history.push('/developers')}}>
                                        developers
                                    </SvgButton>
                                )} */}
                                {/* <Button variant="outlined">Market Tools</Button>
                                <Button variant="outlined">For Realtors</Button> */}
                            </Stack>
                        </Grid>
                    </Grid>
                    {isCalculators && (
                        <CalculatorHeaderMobile />
                    )}
                </div>
            ) : (
                <div>
                    <Grid container style={{
                            paddingTop: "30px",
                            paddingBottom: "40px",
                            paddingLeft: "10%", 
                            paddingRight: "10%",
                        }}
                        direction="row"
                        alignItems="center"
                    >
                        <Grid item xs={6}>
                            <Logo />
                        </Grid>
                        <Grid item xs={6}>
                            <div>
                                <Stack spacing={2} direction="row" justifyContent="flex-end">
                                    {isCalculators ? (
                                        <SvgButtonActive onClick={() => {history.push('/calculators/mortgage-calculator')}}>
                                            Calculators
                                        </SvgButtonActive>
                                    ) : (
                                        <SvgButton onClick={() => {history.push('/calculators/mortgage-calculator')}}>
                                            Calculators
                                        </SvgButton>
                                    )}
                                    {isMarketTools ? (
                                        <SvgButtonActive onClick={() => {history.push('/business-tools')}}>
                                            business tools
                                        </SvgButtonActive>
                                    ) : (
                                        <SvgButton onClick={() => {history.push('/business-tools')}}>
                                            business tools
                                        </SvgButton>
                                    )}
                                    {/* {isDevelopers ? (
                                        <SvgButtonActive onClick={() => {history.push('/developers')}}>
                                            developers
                                        </SvgButtonActive>
                                    ) : (
                                        <SvgButton onClick={() => {history.push('/developers')}}>
                                            developers
                                        </SvgButton>
                                    )} */}
                                    {/* <Button variant="outlined">Market Tools</Button>
                                    <Button variant="outlined">For Realtors</Button> */}
                                </Stack>
                            </div>
                        </Grid>
                    </Grid>
                    {isCalculators && (
                        <CalculatorHeader />
                    )}
                </div>
            )}
        </div>
    )
};