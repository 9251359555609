import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import MenuItem from '@mui/material/MenuItem';

import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';

import { propertyTax } from '../../../Pages/Calculators/calculations';
import CheckCircle from '../../Images/CheckCircle.png';
import CSSTextField from '../../ComponentCatalog/textField';
  
// Importing axios for rest api calls
var axios = require('axios');

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#fff',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
      // color: '#D00000',
    },
    circle: {
        width: 64,
        height: 64,
        borderRadius: '50%',
        border: "4px solid #DA3743",
        backgroundColor: 'currentColor',
        zIndex: "100",
    },
    completed: {
        color: '#D00000',
        zIndex: 1,
        fontSize: 18,
    },
});
  
function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
        className={clsx(classes.root, {
            [classes.active]: active,
        })}
        >
        {completed ? <CheckIcon className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}
  
QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
};

  const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#fff',
        zIndex: 100,
        height: 20, 
        width: 20,
        color: '#fff',
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        border: "2px solid #DA3743",
        '& $img': {
            visibility: "hidden"
        },
        '& $active': {
            color: '#FFFFFF'
        },
        '& $disabled': {
            color: "#FFFFFF"
        }
    },
    active: {
        color: '#fff',
        '& $img': {
            visibility: "hidden"
        }
    },
  
    icon: {
        transform: "scale(2.6)",
    },
  
    completed: {
        '& $img': {
            visibility: "visible"
        }
    }
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        // 1: <CheckCircleIcon size="small" className={classes.icon} />,
        1: <img src={CheckCircle} height={20} width={20} alt="check-circle" />,
        2: <img src={CheckCircle} height={20} width={20} alt="check-circle" />,
        3: <img src={CheckCircle} height={20} width={20} alt="check-circle" />,
        4: <img src={CheckCircle} height={20} width={20} alt="check-circle" />
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};
  
function getSteps() {
    return ['Your Brokerage', 'Your Info', 'Confirm Email'];
}

const useStyles = makeStyles({
    divider: {
      backgroundColor: '#FFFFFF',
    //   color: '#FFFFFF'
    },
    stepper: {
        color: "#FFFFFF",
        '&:active': {
            color: "#FFFFFF"
        }
    },
});

const provinces = [
    {
        value: 'Alberta',
        label: 'Alberta',
    },
    {
        value: 'British Columbia',
        label: 'British Columbia',
    },
    {
        value: 'Manitoba',
        label: 'Manitoba',
    },
    {
        value: 'New Bruinswick',
        label: 'New Bruinswick',
    },
    {
        value: 'Newfoundland and Labrador',
        label: 'Newfoundland and Labrador',
    },
    {
        value: 'Nova Scotia',
        label: 'Nova Scotia',
    },
    {
        value: 'Ontario',
        label: 'Ontario',
    },
    {
        value: 'Prince Edward Island',
        label: 'Prince Edward Island',
    },
    {
        value: 'Quebec',
        label: 'Quebec',
    },
    {
        value: 'Saskatchewan',
        label: 'Saskatchewan',
    },
];

const steps = [
  {
    label: 'Province',
    description: `Property tax rates are dependent 
        on several factors, such as province, city, and the assessed value of the property. 
        Enter your information into the required fields to add property tax to your mortgage payments.`,
  },
  {
    label: 'City',
    description:
      'Enter the city or town within your province:',
  }
];

const ColorButton = styled(Button)({
    color: "#FFFFFF",
    backgroundColor: "#000000",
    border: "1px solid transparent",
    '&:hover': {
        border: "1px solid #CC0000",
        backgroundColor: "#000000",
    },
    textTransform:"none",
    borderRadius: '0px',
});

const ColorButtonActive = styled(Button)({
    color: "#FFFFFF",
    backgroundColor: "#000000",
    border: "1px solid #CC0000",
    textTransform:"none",
    borderRadius: '0px',
    '&:hover': {
        backgroundColor: "#000000",
    },
});

export default function VerticalFormMobile(props) {

    const classes = useStyles();
    const history = useHistory();
    
    const [activeStep, setActiveStep] = React.useState(0);
    const [province, setProvince] = React.useState('Ontario');
    const [cityOptions, setCityOptions] = React.useState([]);
    const [city, setCity] = React.useState("");
    const [propTax, setPropTax] = React.useState(1000);
    const [errorMsg, setErrorMessage] = React.useState('');

    const handleProvChange = (event) => {
        setProvince(event.target.value);
    };

    const handleNext = () => {
        if (props.propertyPrice) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setErrorMessage('');
        } else {
            setErrorMessage("Please add a property purchase price");
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        history.push('/calculators/mortgage-calculator');
    };

    function getStepContent(step) {
        switch (step) {
            case 0:
                return provDetailsForm();
            case 1:
                return cityDetailsForm();
            default:
                return 'Unknown step';
        }
    }

    function provDetailsForm() {
        return (
            <Grid container spacing={2} style={{
                paddingTop: "20px"
            }}>
                <Grid item xs={3} style={{marginTop: "10px"}}>
                    <Typography variant="p" style={{
                        color: "#FFFFFF",
                        fontWeight: "bold"
                    }}>
                        Province:
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <CSSTextField
                        fullWidth 
                        id="custom-css-outlined-input" 
                        value={province}
                        onChange={handleProvChange}
                        select
                    >
                        {provinces.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </CSSTextField>
                </Grid>
                {errorMsg && (
                    <Grid item xs={12}>
                        <Typography variant="p" style={{
                            color: "CC0000",
                            fontWeight: "bold"
                        }}>
                            {errorMsg}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        )
    }

    function cityDetailsForm() {
        return (
            <Grid container spacing={2} style={{
                paddingTop: "20px"
            }}>
                <Grid item xs={3} style={{marginTop: "10px"}}>
                    <Typography variant="p" style={{
                        color: "#FFFFFF",
                        fontWeight: "bold"
                    }}>
                        City:
                    </Typography>
                </Grid>
                {cityOptions && (
                    <Grid item xs={9}>
                        <CSSTextField
                            fullWidth 
                            id="custom-css-outlined-input" 
                            value={city}
                            onChange={event => setCity(event.target.value)}
                            select
                        >
                            {cityOptions.map((option) => (
                                <MenuItem key={option["City"]} value={option["City"]}>
                                    <Typography variant="p" style={{fontWeight:"bold"}}>
                                        {option["City"]}, {(Number(option["Rate"])*100).toFixed(3)}%
                                    </Typography>
                                </MenuItem>
                            ))}
                        </CSSTextField>
                    </Grid>
                )}
            </Grid>
        )
    }

    // function property() {
    //     return (
    //         <Grid container spacing={2} style={{
    //             paddingTop: "20px"
    //         }}>
    //             <Grid item xs={12}>
    //                 <CSSTextField
    //                     fullWidth 
    //                     id="custom-css-outlined-input" 
    //                     value={"$ 1,000"}
    //                     inputProps={{
    //                         style: {fontWeight: "bold", fontSize: "22px"}
    //                     }}
    //                     // onChange={handleCityChange}
    //                 />
    //             </Grid>
    //         </Grid>
    //     )
    // }

    React.useEffect(() => {
        var data = JSON.stringify({
            "province": province
        });
        
        var config = {
            method: 'post',
            url: 'https://g90bq8eoxl.execute-api.us-east-2.amazonaws.com/development/propertytaxes',
            headers: {},
            data : data
        };
        
        axios(config)
        .then(function (response) {
            setCityOptions(response.data);
            setCity(response.data[0]["City"]);
        })
        .catch(function (error) {
            console.log(error);
        });
    }, [province]);

    React.useEffect(() => {
        if (city && cityOptions) {
            setPropTax(propertyTax(cityOptions, city, props.propertyPrice));
            let url = '/calculators/mortgage-calculator?propTax=' + String((propTax/12).toFixed(2));
            
            if (activeStep === 2) {
                history.push(url);
            }
        }
    }, [city, cityOptions, activeStep]);

    return (
        <Box sx={{ maxWidth: 700 }}>
            <Stepper 
                activeStep={activeStep} 
                orientation="vertical" 
                classes={{root: classes.stepper, label: classes.stepper}}
            >
                {steps.map((step, index) => (
                    <Step key={step.label}
                        classes={{
                            root: classes.step,
                            completed: classes.completed,
                            active: classes.active
                    }}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                            <div style={{
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                fontSize: "16px"
                            }}>
                                {step.label}
                            </div>
                        </StepLabel>
                        <StepContent>
                            <Typography style={{fontSize: "12px"}}>{step.description}</Typography>
                            <div>
                                {getStepContent(activeStep)}
                            </div>
                            <Box sx={{ mb: 2 }}>
                                <div style={{paddingTop: "10px"}}>
                                    <ColorButtonActive
                                        disableRipple
                                        variant="contained"
                                        disabled={index === 2}
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                    </ColorButtonActive>
                                    <ColorButton
                                        disableRipple
                                        variant="contained"
                                        disabled={index === 0}
                                        onClick={handleBack}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Back
                                    </ColorButton>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper
                    square 
                    elevation={0} 
                    sx={{ p: 3 }}
                    style={{
                        backgroundColor: "inherit",
                        border: "1px solid #FFFFFF",
                        marginTop: "20px",
                        boxShadow: "0px 4px 20px 15px rgba(238, 237, 255, 0.04)",
                    }}
                >
                    <Typography
                        style={{
                            color: '#FFFFFF',
                            fontWeight: "bold"
                        }}
                    >
                        Your tax payments would be:
                    </Typography>
                    <Typography
                        style={{
                            color: '#FFFFFF',
                            fontWeight: "bold",
                            paddingTop: "35px",
                            fontSize: "20px",
                            textAlign: "right",
                        }}
                    >
                        <NumberFormat
                            value={propTax}
                            className="foo"
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            suffix={' / Yearly'}
                            decimalScale={2}
                            renderText={(value, props) => <div {...props}>{value}</div>}
                        />
                    </Typography>
                    <Typography
                        style={{
                            color: '#FFFFFF',
                            fontWeight: "bold",
                            paddingTop: "10px",
                            fontSize: "20px",
                            textAlign: "right"
                        }}
                    >
                        <NumberFormat
                            value={propTax/12}
                            className="foo"
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            suffix={' / Monthly'}
                            decimalScale={2}
                            renderText={(value, props) => <div {...props}>{value}</div>}
                        />
                    </Typography>
                    <ColorButton 
                        onClick={handleReset} 
                        sx={{ mt: 1, mr: 1 }}
                    >
                        Reset
                    </ColorButton>
                </Paper>
            )}
        </Box>
    );
}