import React from 'react';

// Importing calculations

// import CssSelect from '../../Components/ComponentCatalog/select';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/styles';

const Division = styled(Divider)({
    backgroundColor: "#FFFFFF" 
});


export default function Developers() {

    return (
        <div style={{paddingLeft: "10%", paddingRight: "10%", paddingBottom: "80px"}}>
            <div style={{
                paddingTop: "30px"
            }}>
                <Typography variant="h5" component="div" style={{
                    fontWeight: "bold",
                    color: "#FFFFFF"
                }}>
                    RealEstate+ for Developers
                </Typography>
            </div>    
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        
                    </Grid>
                </Grid>
            </div>    
        </div>
    )
}