import React, { useState, useEffect } from 'react';

import Macbook from '../Components/LandingPage/macbook';
import MacbookMobile from '../Components/LandingPage/Mobile/macbook';
import FloatingFunctionIcons from '../Components/LandingPage/floatingFunctionIcons';
import FloatingDollarIcons from '../Components/LandingPage/floatingDollarIcons';
import FloatingFunctionIconsMobile from '../Components/LandingPage/Mobile/floatingFunctionIcons';
import FloatingDollarIconsMobile from '../Components/LandingPage/Mobile/floatingDollarIcons';
import SuiteSection from '../Components/LandingPage/suite';
import SuiteSectionMobile from '../Components/LandingPage/Mobile/suite';
import { useWindowSize } from '../libs/responsiveLib';
import SvgButton from '../Components/Headers/headerButton';
import Suite from '../Components/LandingPage/suitev5';
// import Footer from '../Components/ComponentCatalog/footer';

// import siteLogo from '../Components/Images/Logo(Light).png';
import valueProp1 from '../Components/Images/valueProp1.png';
import valueProp2 from '../Components/Images/valueProp2.png';
import valueProp3 from '../Components/Images/valueProp3.png';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    divider: {
      backgroundColor: '#FFFFFF',
    //   color: '#FFFFFF'
    },
});

export default function Home() {
    const size = useWindowSize();
    const classes = useStyles();

    // Determining mobile screen sizing
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(size.width <= 600);
    }, [size]);

    // Rendering Desktop version
    return (
        <div style={{
            backgroundColor: "linear-gradient(0deg, rgba(16, 2, 183, 0.12), rgba(16, 2, 183, 0.12)), #212020"
        }}>
            <div style={{paddingTop: "50px"}}>
                <div className="initalDiv" style={{paddingBottom: "50px"}}>
                    {isMobile ? (
                        <div>
                            <div className="initalDiv" style={{overflowX: "hidden"}}>
                            
                                <Typography variant="h6" component="div" style={{
                                    fontWeight: "bold",
                                    color: "#FFFFFF",
                                    textAlign: "center",
                                    lineHeight: "22px"
                                }}>
                                    Business intelligence software
                                    <br /> 
                                    for agents and brokerages.
                                </Typography>
        
                                <Grid container fluid style={{paddingTop: "20px"}}>
                                    <Grid item xs={2}>
                                        <div style={{marginRight: "-30%"}}>
                                            <FloatingFunctionIconsMobile />
                                        </div>
                                    </Grid>
                                    <Grid item xs={8} alignItems="center" style={{zIndex: "100"}}>
                                        <div style={{textAlign: "center"}}>
                                            <MacbookMobile />
                                        </div>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{marginLeft: "-30%"}}>
                                            <FloatingDollarIconsMobile />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography 
                                            variant="p" 
                                            component="div" 
                                            style={{
                                                paddingTop: "50px", 
                                                textAlign: "center",
                                                color: "#FFFFFF"
                                            }}
                                        >
                                            We help real estate agents make 
                                            <br /> 
                                            more money by helping them 
                                            <br /> 
                                            understand their income goal and 
                                            <br /> 
                                            how to get there.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center">
                                <Grid item xs={3.5}>
                                    <div>
                                        <FloatingFunctionIcons />
                                    </div>
                                </Grid>
                                <Grid item xs={5} style={{zIndex: "100"}}>
                                    <div>
                                        <Box sx={{ 
                                            mx: 'auto', 
                                            p: 1,
                                            m: 1,
                                            textAlign: "center" 
                                        }}>
                                            <Macbook />
                                        </Box>
                                    </div>
                                </Grid>
                                <Grid item xs={3.5}>
                                    <div>
                                        <FloatingDollarIcons />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} alignItems="center" justifyContent="center" direction="column">
                                <Grid item>
                                    <Typography variant="h3" component="div" style={{
                                        fontWeight: "bold",
                                        color: "#FFFFFF",
                                        textAlign: "center"
                                    }}>
                                        Business intelligence software
                                        <br /> 
                                        for agents and brokerages.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6" component="div" style={{
                                        color: "#EEEDFF",
                                        textAlign: "center",
                                        lineHeight: "19px"
                                    }}>
                                        We help real estate agents make more money by 
                                        <br /> 
                                        helping them understand their income goal and 
                                        <br /> 
                                        how to get there.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </div>

                <div style={{textAlign: "center"}}>
                    <SvgButton onClick={() => window.open("https://www.app.realestateplus.org/login")}>Login</SvgButton>
                </div>

                <div style={{paddingTop: "50px"}}>
                    <Divider classes={{root: classes.divider}} />
                </div>

                <div style={{paddingTop: '100px'}}>
                    
                    {isMobile ? (
                        <div style={{paddingLeft: "5%", paddingRight: "5%", textAlign: "center"}}>
                            <Typography variant="h6" component="div" style={{
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                lineHeight: "22px"
                            }}>
                                A suite of tools tailored towards your business to earn more revenue.
                            </Typography>
                        </div>
                    ) : (
                        <div className="suiteText">   
                            <Grid
                                container
                                direction="column"
                                justify="flex-start"
                            >
                                <Grid item xs={12}>
                                    <div style={{paddingLeft: "10%", paddingRight: "10%", textAlign: "center"}}>
                                        <Typography variant="h4" component="div" style={{
                                            color: "#FFFFFF",
                                            fontWeight: "bold",
                                        }}>
                                            A suite of tools tailored towards your business to earn more revenue.
                                        </Typography>
                                    </div>
                                </Grid>
                            
                            </Grid>
                        </div>
                    )}
                    
                    {/* Suite section */}
                    {size.width > 1200 ? (
                        <div className="suiteSection" style={{textAlign: 'center', paddingTop: "50px", paddingLeft: "6%", paddingRight: "10%"}}>
                            <div style={{width: '1000px', marginLeft: 'auto', marginRight: 'auto'}}>
                                <Suite />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div>
                                <SuiteSectionMobile />
                            </div>
                        </div>
                    )}
                </div>

                <div style={{paddingTop: "100px"}}>
                    <Divider classes={{root: classes.divider}} />
                </div>

                {isMobile ? (
                    <div style={{paddingBottom: "50px"}}>
                        <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                        
                            {/* Value prop 1 */}
                            <Grid item style={{paddingTop: "50px"}}>
                                <Typography 
                                    variant="p" 
                                    component="div" 
                                    style={{
                                        color: "#FFFFFF", 
                                        fontWeight: "bold",
                                        fontSize: "18px"
                                    }}
                                >
                                    We are not a CRM
                                </Typography>
                            </Grid>
                            <Grid item style={{paddingTop: "50px"}}>
                                <div>
                                    <img src={valueProp1} width={216} height={173} alt="Not a CRM" />
                                </div>
                            </Grid>
                            <Grid item style={{paddingTop: "50px"}}>
                                <div style={{paddingLeft: "20%", paddingRight: "20%"}}>
                                    <Typography 
                                        variant="p" 
                                        component="div" 
                                        style={{color: "#FFFFFF"}}
                                    >
                                        Unlike your CRM software, we provide users an analytical approach to their business and team. Say goodbye to archaic tables and old methods. RE+ offers a new way of seeing you and your business that will make you thrive.
                                    </Typography>
                                </div>
                            </Grid>
                            
                            {/* Value prop 2 */}
                            <Grid item style={{paddingTop: "50px"}}>
                                <Typography 
                                    variant="p" 
                                    component="div" 
                                    style={{
                                        color: "#FFFFFF", 
                                        fontWeight: "bold",
                                        fontSize: "18px"
                                    }}
                                >
                                    In-depth analytics
                                </Typography>
                            </Grid>
                            <Grid item style={{paddingTop: "50px"}}>
                                <div>
                                    <img src = {valueProp2} width={206} height={163} alt="In-depth Analytics" />
                                </div>
                            </Grid>
                            <Grid item style={{paddingTop: "50px"}}>
                                <div style={{paddingLeft: "20%", paddingRight: "20%"}}>
                                    <Typography 
                                        variant="p" 
                                        component="div" 
                                        style={{color: "#FFFFFF"}}
                                    >
                                        Take a deep dive into your business acivities and see how they directly correlate to your earnings goal. Get all relevant indicators and more at the click of a button. See yourself and your analytics like never before.
                                    </Typography>
                                </div>
                            </Grid>

                            {/* Value prop 3 */}
                            <Grid item style={{paddingTop: "50px"}}>
                                <Typography 
                                    variant="p" 
                                    component="div" 
                                    style={{
                                        color: "#FFFFFF", 
                                        fontWeight: "bold",
                                        fontSize: "18px"
                                    }}
                                >
                                    Brokerage-wide adoptability
                                </Typography>
                            </Grid>
                            <Grid item style={{paddingTop: "50px"}}>
                                <div>
                                    <img src = {valueProp3} width={266} height={143} alt="Brokerage Wide Adoptability" />
                                </div>
                            </Grid>
                            <Grid item style={{paddingTop: "50px"}}>
                                <div style={{paddingLeft: "20%", paddingRight: "20%"}}>
                                    <Typography 
                                        variant="p" 
                                        component="div" 
                                        style={{color: "#FFFFFF"}}
                                    >
                                        Whether you’re a single agent or an entire brokerage, we provide the same value-add across the board. Manage and track team-wide performance. RE+ can handle any team of any size. Do you have a large team? Contact us for a personalized quote.
                                    </Typography>
                                </div>
                            </Grid>

                        </Grid>
                    </div>
                ) : (
                    <div 
                        style={{
                            paddingTop: "100px",
                            paddingLeft: "10%",
                            paddingRight: "10%",
                            paddingBottom: "100px"
                        }}
                    >
                        <Grid container spacing={2}>
                            
                            {/* Value prop 1 */}
                            <Grid item xs={6} style={{paddingTop: "60px"}}>
                                <Typography 
                                    style={{
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: "25px"
                                    }}
                                >
                                    We are not a CRM
                                </Typography>
                                <Typography 
                                    style={{
                                        paddingTop: "20px",
                                        color: "white"
                                    }}
                                >
                                    Unlike your CRM software, we provide users an analytical approach to their business and team. Say goodbye to archaic tables and old methods. RE+ offers a new way of seeing you and your business that will make you thrive.
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{marginTop: "-20px"}}>
                                <div style={{textAlign: "center"}}>
                                    <img src={valueProp1} width={402} height={339} alt="Not a CRM" />
                                </div>
                            </Grid>
                            
                            {/* Value prop 2 */}
                            <Grid item xs={6} style={{marginTop: "-20px"}}>
                                <div style={{textAlign: "center"}}>
                                    <img src = {valueProp2} width={390} height={350} alt="In-depth Analytics" />
                                </div>
                            </Grid>
                            <Grid item xs={6} style={{paddingTop: "80px"}}>
                                <Typography 
                                    style={{
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: "25px"
                                    }}
                                >
                                    In-depth analytics
                                </Typography>
                                <Typography 
                                    style={{
                                        paddingTop: "20px",
                                        color: "white"
                                    }}
                                >
                                    Take a deep dive into your business acivities and see how they directly correlate to your earnings goal. Get all relevant indicators and more at the click of a button. See yourself and your analytics like never before.
                                </Typography>
                            </Grid>

                            {/* Value prop 3 */}
                            <Grid item xs={6} style={{paddingTop: "80px"}}>
                                <Typography 
                                    style={{
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: "25px"
                                    }}
                                >
                                    Brokerage-wide adoptability
                                </Typography>
                                <Typography 
                                    style={{
                                        paddingTop: "20px",
                                        color: "white"
                                    }}
                                >
                                    Whether you’re a single agent or an entire brokerage, we provide the same value-add across the board. Manage and track team-wide performance. RE+ can handle any team of any size. Do you have a large team? Contact us for a personalized quote.
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{marginTop: "-20px"}}>
                                <div style={{textAlign: "center"}}>
                                    <img src = {valueProp3} width={470} height={270} alt="Brokerage Wide Adoptability" />
                                </div>
                            </Grid>

                        </Grid>
                    </div>
                )}
                <Divider classes={{root: classes.divider}} />
                <div
                    style={{
                        paddingTop: "100px",
                        paddingBottom: "100px"
                    }}
                >
                    <Typography
                        style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "28px",
                            textAlign: "center"
                        }}
                    >
                        Ready to Upgrade Your Business?
                    </Typography>

                    <div style={{textAlign: "center", paddingTop: "25px"}}>
                        <SvgButton onClick={() => window.open("https://www.app.realestateplus.org/login")}>Login</SvgButton>
                    </div>
                </div>
            </div>
        </div>
    );
}