import React, { Component } from 'react';
import macbook from '../Images/Macbook.png';
import './macbook.css';

import Box from '@mui/material/Box';

class Macbook extends Component {
    render() {
        return (
            <div>
                <Box sx={{ mx: 'auto', p: 1 }}>
                    <img  
                        src={macbook} 
                        alt="Real Estate Plus logo" 
                        width={500}
                    />
                </Box>
            </div>
        );
    } 
}

export default Macbook;