//Importing React files
import React from 'react';
import { makeStyles } from '@mui/styles';

//Importing files within application
import './App.css';
import Routes from './Routes';
import Footer from './Components/ComponentCatalog/footer';

const useStyles = makeStyles((theme) => {
  root: {
    
  }
});

function App() {
  const classes = useStyles();
  
  return (
    <div className="App">
        <Routes />
        <Footer />
      </div>
  );
}

export default App;