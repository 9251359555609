import React, { Component } from 'react';
import macbook from '../../Images/Macbook.png';
import '../macbook.css';

class MacbookMobile extends Component {
    render() {
        return (
            <div className="macbook-image">
                <img src={macbook} alt="Real Estate Plus logo" width={235} height={149} />
            </div>
        );
    } 
}

export default MacbookMobile;