import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

// Importing icons
import teamIcon from "../Images/TeamIcon.png";
import analyticsIcon from '../Images/analyticsIcon.png';
import goalsIcon from '../Images/goalsIcon.png';
import calendarIcon from '../Images/calendarIcon.png';
import performanceIcon from '../Images/PerformanceIcon.png';
import dollarIcon from '../Images/dollarIcon(Red).png';
import { Divider } from '@material-ui/core';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: 'none',
      fontWeight: "bold",
      fontSize: "30px",
      marginRight: theme.spacing(1),
      color: 'rgba(255, 255, 255, 0.7)',
      '&.Mui-selected': {
        color: '#fff',
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
      },
    }),
  );

export default function Suite() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ bgcolor: 'inherit', display: 'flex' }}
    >
        <Tabs
            orientation="vertical"
            style={{width: "45%", marginTop: "40px"}}
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
                style: {
                    backgroundColor: "#FFFFFF",
                    left: "0px"
                }
            }}
        >
            <StyledTab label="Goal Generation" {...a11yProps(0)} />
            <StyledTab label="Daily Tracking" {...a11yProps(1)} />
            <StyledTab label="Performance Review" {...a11yProps(2)} />
            <StyledTab label="Analytics & Insights" {...a11yProps(3)} />
            <StyledTab label="Team Management" {...a11yProps(4)} />
            <StyledTab label="Easy Adoption" {...a11yProps(5)} />
        </Tabs>
            <TabPanel
                // className={classes.tabPanel} 
                value={value} 
                index={0}
            >
                <div>
                    <div style={{paddingLeft: '50%'}}>
                        <Paper 
                            variant="outlined" 
                            style={{
                                width: '338px',
                                height: '338px',
                                backgroundColor: '#000000',
                                border: '3px solid #CC0000',
                                borderRadius: 0, 
                            }}
                        >
                        </Paper>
                    </div>
                    <div>
                        <Paper 
                            style={{
                                width: '310px',
                                height: '315px',
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                backgroundColor: "#EEEDFF",
                                marginTop: '-240px',
                                borderRadius: '0px',
                                textAlign: "center",
                                boxShadow: "0px 4px 20px 15px rgba(238, 237, 255, 0.06)"
                            }}
                            elevation={0}
                        >
                            <div style={{paddingTop: '60px', textAlign: "center"}}>
                                <img src={goalsIcon} alt="Target Icon" width={81} height={81} />
                                <h6
                                    style={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '18px',
                                        lineHeight: '26px',
                                        marginTop: '30px',  
                                    }}
                                >
                                    Tell us your income goal for the year. We will generate a list of what you need to get done to achieve that goal.
                                </h6>
                            </div>
                        </Paper>
                    </div>
                </div>
            </TabPanel>
            <TabPanel
                value={value} 
                index={1}
            >
                <div>
                    <div style={{paddingLeft: '50%'}}>
                        <Paper 
                            variant="outlined" 
                            style={{
                                width: '338px',
                                height: '338px',
                                backgroundColor: '#000000',
                                border: '3px solid #CC0000',
                                borderRadius: 0, 
                            }}
                        >
                        </Paper>
                    </div>
                    <div>
                        <Paper 
                            style={{
                                width: '310px',
                                height: '315px',
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                backgroundColor: "#EEEDFF",
                                marginTop: '-240px',
                                borderRadius: '0px',
                                textAlign: "center",
                                boxShadow: "0px 4px 20px 15px rgba(238, 237, 255, 0.06)"
                            }}
                            elevation={0}
                        >
                            <div style={{paddingTop: '60px', textAlign: "center"}}>
                                <img src={calendarIcon} alt="Calendar Icon" width={81} height={81} />
                                <h6
                                    style={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '18px',
                                        lineHeight: '26px',
                                        marginTop: '30px',  
                                    }}
                                >
                                    Track your day-to-day actvities as an agent to stay on top of your game.
                                </h6>
                            </div>
                        </Paper>
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <div>
                    <div style={{paddingLeft: '50%'}}>
                        <Paper 
                            variant="outlined" 
                            style={{
                                width: '338px',
                                height: '338px',
                                backgroundColor: '#000000',
                                border: '3px solid #CC0000',
                                borderRadius: 0, 
                            }}
                        >
                        </Paper>
                    </div>
                    <div>
                        <Paper 
                            style={{
                                width: '310px',
                                height: '315px',
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                backgroundColor: "#EEEDFF",
                                marginTop: '-240px',
                                borderRadius: '0px',
                                textAlign: "center",
                                boxShadow: "0px 4px 20px 15px rgba(238, 237, 255, 0.06)"
                            }}
                            elevation={0}
                        >
                            <div style={{paddingTop: '60px', textAlign: "center"}}>
                                <img src={performanceIcon} alt="Medal Icon" width={81} height={81} />
                                <h6
                                    style={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '18px',
                                        lineHeight: '26px',
                                        marginTop: '30px',  
                                    }}
                                >
                                    See how you’re doing compared to your goals. Managers, analyze your team and identify top performers.
                                </h6>
                            </div>
                        </Paper>
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <div>
                    <div style={{paddingLeft: '50%'}}>
                        <Paper 
                            variant="outlined" 
                            style={{
                                width: '338px',
                                height: '338px',
                                backgroundColor: '#000000',
                                border: '3px solid #CC0000',
                                borderRadius: 0, 
                            }}
                        >
                        </Paper>
                    </div>
                    <div>
                        <Paper 
                            style={{
                                width: '310px',
                                height: '315px',
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                backgroundColor: "#EEEDFF",
                                marginTop: '-240px',
                                borderRadius: '0px',
                                textAlign: "center",
                                boxShadow: "0px 4px 20px 15px rgba(238, 237, 255, 0.06)"
                            }}
                            elevation={0}
                        >
                            <div style={{paddingTop: '60px', textAlign: "center"}}>
                                <img src={analyticsIcon} alt="Analytics Icon" width={81} height={81} />
                                <h6
                                    style={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '18px',
                                        lineHeight: '26px',
                                        marginTop: '30px',  
                                    }}
                                >
                                    Get relevant insights on <br/> performance that tell a story.
                                </h6>
                            </div>
                        </Paper>
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <div>
                    <div style={{paddingLeft: '50%'}}>
                        <Paper 
                            variant="outlined" 
                            style={{
                                width: '338px',
                                height: '338px',
                                backgroundColor: '#000000',
                                border: '3px solid #CC0000',
                                borderRadius: 0, 
                            }}
                        >
                        </Paper>
                    </div>
                    <div>
                        <Paper 
                            style={{
                                width: '310px',
                                height: '315px',
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                backgroundColor: "#EEEDFF",
                                marginTop: '-240px',
                                borderRadius: '0px',
                                textAlign: "center",
                                boxShadow: "0px 4px 20px 15px rgba(238, 237, 255, 0.06)"
                            }}
                            elevation={0}
                        >
                            <div style={{paddingTop: '60px', textAlign: "center"}}>
                                <img src={teamIcon} alt="Team Icon" width={81} height={81} />
                                <h6
                                    style={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '18px',
                                        lineHeight: '26px',
                                        marginTop: '30px',  
                                    }}
                                >
                                    Oversee your entire brokerage with one account. Manage and monitor your sales team like never before.
                                </h6>
                            </div>
                        </Paper>
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={5}>
                <div>
                    <div style={{paddingLeft: '50%'}}>
                        <Paper 
                            variant="outlined" 
                            style={{
                                width: '338px',
                                height: '338px',
                                backgroundColor: '#000000',
                                border: '3px solid #CC0000',
                                borderRadius: 0, 
                            }}
                        >
                        </Paper>
                    </div>
                    <div>
                        <Paper 
                            style={{
                                width: '310px',
                                height: '315px',
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                backgroundColor: "#EEEDFF",
                                marginTop: '-240px',
                                borderRadius: '0px',
                                textAlign: "center",
                                boxShadow: "0px 4px 20px 15px rgba(238, 237, 255, 0.06)"
                            }}
                            elevation={0}
                        >
                            <div style={{paddingTop: '60px', textAlign: "center"}}>
                                <img src={dollarIcon} alt="Dollar Icon" width={81} height={81} />
                                <h6
                                    style={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '18px',
                                        lineHeight: '26px',
                                        marginTop: '30px',  
                                    }}
                                >
                                    Integrate our software with your workflow seamlessly, and get completely set up within the hour.
                                </h6>
                            </div>
                        </Paper>
                    </div>
                </div>
            </TabPanel>
    </Box>
  );
}