// Importing motion library
import { motion } from "framer-motion";

// Importing icons
import teamIcon from "../Images/TeamIcon.png";
import analyticsIcon from '../Images/analyticsIcon.png';
import goalsIcon from '../Images/goalsIcon.png';

export default function FloatingFunctionIcons() {
    return (
        <div>
            <motion.div
                initial={{
                    x: '-100%',
                }}
                animate={{
                    x: '150%',
                    y: '150%'
                }}
                transition={{
                    type: 'tween',
                    ease: 'easeInOut',
                    repeat: Infinity,
                    // repeatType: 'reverse',
                    repeatDelay: 0,
                    duration: 4,
                    delay: 0.5
                }}
            >
                <img src={teamIcon} alt="Floating Team Icon" width={81} height={81} />
            </motion.div>
            <motion.div
                initial={{
                    x: '-100%',
                }}
                animate={{
                    x: '150%',
                    y: '-10%'
                }}
                transition={{
                    type: 'tween',
                    ease: 'easeInOut',
                    repeat: Infinity,
                    // repeatType: 'cycle',
                    repeatDelay: 0,
                    duration: 4,
                    delay: 1.5
                }}
            >
                <img src={goalsIcon} alt="Floating Team Icon" width={81} height={81} />
            </motion.div>
            <motion.div
                initial={{
                    x: '-100%',
                }}
                animate={{
                    x: '150%',
                    y: '-45%'
                }}
                transition={{
                    type: 'tween',
                    ease: 'easeInOut',
                    repeat: Infinity,
                    // repeatType: 'r',
                    repeatDelay: 0,
                    duration: 4,
                    delay: 3
                }}
            >
                <img src={analyticsIcon} alt="Floating Team Icon" width={81} height={81} />
            </motion.div>
        </div>
    )
}
