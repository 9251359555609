import React from 'react';

import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const CssTextField = styled(TextField)({
    textDecoration: "none",
    backgroundColor: 'linear-gradient(0deg, rgba(16, 2, 183, 0.12), rgba(16, 2, 183, 0.12)), #212020',
    '& label.Mui-focused': {
        color: '#FFFFFF',
    },
    borderColor: "#CC0000",
    '& .MuiInput-underline:after': {
        borderBottomColor: '#CC0000',
        borderRadius: '0px;',
    },
    '& .MuiOutlinedInput-root': {
        color: "#FFFFFF",
        '& fieldset': {
            borderColor: '#CC0000;',
            borderRadius: '0px;',
            color: '#FFFFFF',
        },
        '&:hover fieldset': {
            borderColor: '#CC0000;',
            borderRadius: '0px;',
            color: '#FFFFFF',
        //   backgroundColor: '#F5F7F9',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#CC0000',
            color: '#FFFFFF',
        //   backgroundColor: '#F5F7F9',
        },
    },
    '& .MuiOutlinedInput-input': {
        '& fieldset': {
            color: '#FFFFFF',
        },
        '&:hover fieldset': {
            color: '#FFFFFF',
        //   backgroundColor: '#F5F7F9',
        },
        '&.Mui-focused fieldset': {
            color: '#FFFFFF',
        //   backgroundColor: '#F5F7F9',
        },
    },
  });

export default CssTextField;