import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

// Importing material ui components
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Typography } from '@material-ui/core';

export default function CalculatorHeader() {
    const [stockList, setStockList] = useState([]);
    // const [callCount, setCallCount] = useState(0);
    
    // function fetchStock() {
    //     let API_KEY = process.env.REACT_APP_MARKETSTACK_KEY
    //     let stockTicker = 'MSFT'
    //     let URL = `http://api.marketstack.com/v1/eod?access_key=${API_KEY}&symbols=${stockTicker}`
    
    //     fetch(URL).then(res => res.json())
    //     .then(data => setStockList(data))
    // }


    
    // useEffect(() => {
    //     // Timeout set for 2 seconds before retrying call
    //     if (callCount < 4) {
    //         const timer = setTimeout(() => {
    //             if (stockList === []) {
                    
    //                 console.log('This will run after 2 second!');
    //                 console.log(callCount);
    //                 fetchStock();
    //                 console.log(stockList);
    //                 // Bocking call after 4 attempts
    //                 let newCount = callCount + 1;
    //                 setCallCount(newCount);
    
    //             }
    //         }, 2000);
    //     } else {
    //         return
    //     }
    //     // return () => clearTimeout(timer);
    // });
    
    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText("#000000"),
        backgroundColor: "#000000",
        border: "1px solid transparent",
        '&:hover': {
            border: "1px solid #CC0000",
            backgroundColor: "#000000",
        },
        textTransform:"none",
        borderRadius: '0px',
        boxShadow: "0px 4px 20px 15px rgba(238, 237, 255, 0.02)",
    }));
    
    const ColorButtonActive = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText("#000000"),
        backgroundColor: "#000000",
        border: "1px solid #CC0000",
        textTransform:"none",
        borderRadius: '0px',
        '&:hover': {
            backgroundColor: "#000000",
        },
        boxShadow: "0px 4px 20px 15px rgba(238, 237, 255, 0.02)",
    }));

    const location = useLocation();
    const history = useHistory();

    let isMortgage = location.pathname === "/calculators/mortgage-calculator";
    let isRental = location.pathname === "/calculators/rental-income";
    let isInitial = location.pathname === "/calculators/initial-investment";

    return (
        <div>
            {stockList && (
                <div style={{
                    paddingLeft: "25px",
                    height: "35px",
                    backgroundColor: "inherit",
                }}>
                    {/* <div>
                        <Typography variant="p" style={{color: "#FFFFFF"}}>
                            RBC REIT 300/30 Index: ^22.75
                        </Typography>
                    </div> */}
                    <Grid container spacing={2} alignItems="center">
                        {/* <Grid item>
                             <Typography style={{
                                 fontSize: "18px",
                                 marginTop: "-13px",
                                 fontWeight: "bold",
                                 color: "#CC0000",
                                 paddingLeft: "40px"
                             }}>
                                 Calculators:
                             </Typography>
                        </Grid> */}
                        <Grid 
                            item xs={12}
                        >
                            <Stack justifyContent="center" direction="row" spacing={2}>
                                {isMortgage ? (
                                    <ColorButtonActive 
                                        size="small" 
                                        variant="contained"
                                        onClick={() => {history.push('/calculators/mortgage-calculator')}}
                                    >
                                        mortgage calculator
                                    </ColorButtonActive>
                                ) : (
                                    <ColorButton 
                                        size="small" 
                                        variant="contained"
                                        onClick={() => {history.push('/calculators/mortgage-calculator')}}
                                    >
                                        mortgage calculator
                                    </ColorButton>
                                )}
                                {isRental ? (
                                    <ColorButtonActive 
                                        size="small" 
                                        variant="contained"
                                        onClick={() => {history.push('/calculators/rental-income')}}
                                    >
                                        rental income
                                    </ColorButtonActive>
                                ) : (
                                    <ColorButton 
                                        size="small" 
                                        variant="contained"
                                        onClick={() => {history.push('/calculators/rental-income')}}
                                    >
                                        rental income
                                    </ColorButton>
                                )}
                                {/* {isInitial ? (
                                    <ColorButtonActive 
                                        size="small" 
                                        variant="contained"
                                        onClick={() => {history.push('/calculators/initial-investment')}}
                                    >
                                        initial investment
                                    </ColorButtonActive>
                                ) : (
                                    <ColorButton 
                                        size="small" 
                                        variant="contained"
                                        onClick={() => {history.push('/calculators/initial-investment')}}
                                    >
                                        initial investment
                                    </ColorButton>
                                )} */}
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    )
}