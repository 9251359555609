// Importing react components
import React from 'react';

// Importing material-ui components
import { styled } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';

// Importing images
import realEstatePlusLogo from '../Images/Logo(Light).png';
import facebookLogo from '../Images/facebookLogo.png';
import linkedInLogo from '../Images/linkedInLogo.png';

const FooterBox = styled(Box)({
    background: 'linear-gradient(0deg, rgba(16, 2, 183, 0.22), rgba(16, 2, 183, 0.22)), #000000',
    paddingTop: '2.5%',
    paddingBottom: '5%',
    paddingLeft: '2.5%',
    paddingRight: '2.5%'
});


function FooterLink(props) {
    return (
        <Link 
            style={{
                fontWeight: '500',
                fontSize: '12px',
                lineHeight: '15px',
                color: '#FFFFFF',
                '&:hover': {
                    color: '#FFFFFF'
                } 
            }}
            {...props}
        >
            {props.children}
        </Link>
    );
}

const Division = styled(Divider)({
    backgroundColor: "#FFFFFF" 
});

// const useStyles = makeStyles({
//     footer: {
//         background: 'linear-gradient(0deg, rgba(16, 2, 183, 0.22), rgba(16, 2, 183, 0.22)), #000000',
//         paddingTop: '2.5%',
//         paddingBottom: '5%',
//         paddingLeft: '2.5%',
//         paddingRight: '2.5%'
//     },
//     link: {
//         fontWeight: '500',
//         fontSize: '12px',
//         lineHeight: '15px',
//         color: '#FFFFFF',
//         '&:hover': {
//             color: '#FFFFFF'
//         }
//     },
//     text: {
//         fontWeight: '500',
//         fontSize: '10px',
//         lineHeight: '14px',
//         color: '#FFFFFF'
//     },
//     divider: {
//         backgroundColor: "#FFFFFF"
//     }
// });

export default function Footer() {
    // const classes = useStyles();

    return (
        <div>
            <Division />
            <FooterBox>
                <Container disableGutters={true} maxWidth={false}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Box mb='25px'>
                                        <a href='/'>
                                            <img
                                                src={realEstatePlusLogo}
                                                alt='RealEstate+ Logo'
                                                style={{
                                                    width: '171.98px', 
                                                    height: '36.68px'
                                                }}
                                            />
                                        </a>
                                    </Box>
                                    <Box mb='25px'>
                                        <a href='/'>
                                            <img
                                                src={facebookLogo}
                                                alt='Facebook Logo'
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    marginRight: '15px'
                                                }}
                                            />
                                        </a>
                                        <a href='/'>
                                            <img
                                                src={linkedInLogo}
                                                alt='LinkedIn Logo'
                                                style={{
                                                    width: '20px',
                                                    height: '20px'
                                                }}
                                            />
                                        </a>
                                    </Box>
                                    <Box mb='10px'>
                                        <Typography style={{
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            lineHeight: '14px',
                                            color: '#FFFFFF'
                                        }}>
                                            hello@realestateplus.org
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography style={{
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            lineHeight: '14px',
                                            color: '#FFFFFF'
                                        }}>
                                            {'RealEstate+ \xA92021'}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box height='36.68px' mb='25px'>
                                        <br/>
                                    </Box>
                                    <Box mb='10px'>
                                        <Typography>
                                            <FooterLink href='/calculators'>
                                                Calculators
                                            </FooterLink>
                                        </Typography>
                                    </Box>
                                    <Box mb='10px'>
                                        <Typography>
                                            <FooterLink href='/business-tools'>
                                                Business Tools
                                            </FooterLink>
                                        </Typography>
                                    </Box>
                                    {/* <Box mb='10px'>
                                        <Typography>
                                            <FooterLink href='/developers'>
                                                Developers
                                            </FooterLink>
                                        </Typography>
                                    </Box> */}
                                    <Box>
                                        <Typography>
                                            <FooterLink href='/'>
                                                {'Fran\xE7ais'}
                                            </FooterLink>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box  height='36.68px' mb='25px'>
                                        <br/>
                                    </Box>
                                    <Box mb='10px'>
                                        <Typography>
                                            <FooterLink href='/calculators'>
                                                Canadian Mortgage Calculator
                                            </FooterLink>
                                        </Typography>
                                    </Box>
                                    <Box mb='10px'>
                                        <Typography>
                                            <FooterLink href='/calculators/rental-income'>
                                                Rental Income Calculator
                                            </FooterLink>
                                        </Typography>
                                    </Box>
                                    {/* <Box mb='10px'>
                                        <Typography>
                                            <FooterLink href='/calculators/initial-investment'>
                                                Initial Investment Calculator
                                            </FooterLink>
                                        </Typography>
                                    </Box>
                                    <Box mb='10px'>
                                        <Typography>
                                            <FooterLink href='/calculators/amortization-schedule'>
                                                Amortization Schdeule
                                            </FooterLink>
                                        </Typography>
                                    </Box> */}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs md={6}>
                        </Grid>
                    </Grid>
                </Container>
            </FooterBox>
        </div>
    );
}









